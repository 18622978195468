import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormatterService {

  constructor() { }

  
  //For format yyyy-MM-dd
  returnFormatedDate(inputDate: any) : any {
   let month = inputDate.getMonth() + 1;
   return inputDate.getFullYear() + '-' + 
    ("0" + month).slice(-2) + '-' + 
    ("0" + inputDate.getDate()).slice(-2);
  }

  //For format MM/dd/yyyy
  returnFormatedDate2(inputDate: any) : any {
    let month = inputDate.getMonth() + 1;
    return ("0" + month).slice(-2) + '/' + ("0" + inputDate.getDate()).slice(-2) + '/' + inputDate.getFullYear();
  }
  
}
