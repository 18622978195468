import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-regular-sample-medicine',
  templateUrl: './regular-sample-medicine.component.html',
  styleUrls: ['./regular-sample-medicine.component.sass']
})
export class RegularSampleMedicineComponent {
  constructor(public dialogRef: MatDialogRef<RegularSampleMedicineComponent>) { }
  popupClose() {
    this.dialogRef.close();
  }
}


