import { Injectable } from "@angular/core";

@Injectable()
export class ServiceUrl {
  port: string;
  mpPort: string;
  survey: string;
  admin: string;
  payroll: string;
  verification: string;
  marketPlace: string;
  affiliateMarketing: string;
  constructor() {
    this.port = 'https://apitelehealth.drstellamd.com/'; //Prod env telehealth
    //this.port = 'https://demoapitelehealth.drstellamd.com:441/'; //Demo env telehealth
    //this.port = 'http://localhost:5000/'; //local env telehealth


    //this.mpPort = "https://marketplaceadmin.drstellamd.com/"; //prod marketplaceadmin
    this.mpPort = "https://marketplaceadmin.drstellamd.com/"; //prod marketplaceadmin

    this.survey = this.port + 'api/survey/';
    this.admin = this.port + 'api/admin/';
    this.payroll = this.port + 'api/payroll/';
    this.affiliateMarketing = this.port + 'api/affiliatemarket/';
    this.verification = this.port + 'api/verification/';
    this.marketPlace = this.mpPort + "api/marcketplace/";
    
  }

}
