<div class="card">
    <div class="body">
        <div class="row">
            <form [formGroup]="patientStateForm" (ngSubmit)="submitPatientStateForm()" style="width: 100%;">
                <div class="row">
                    <div class="col-md-8">
                        <h4>Select State</h4>
                    </div>
                    <div class="col-md-4">
                        <button mat-icon-button type="button" class="close-button" (click)="acuteCareCloseClick($event);">
                            <mat-icon class="close-icon" color="warn">close</mat-icon>
                          </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <mat-form-field appearance="standard" style="width: 90%;">
                            <mat-label>Select your state<span style="color: red;">*</span></mat-label>
                            <mat-select formControlName="state" (selectionChange)="stateChange($event)">
                                <mat-option *ngFor="let state of data" [value]="state.id">{{state.name}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="checkError('state', 'required')">Please select state</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-12" style="margin-bottom: 0px !important;">
                        <div class="row">
                            <div class="col-md-10">
                                <mat-label>Upload Driving License
                                    <span style="color: red;">* </span>
                                </mat-label>
                            </div>
                            <div class="col-md-2">
                                <button type="button" mat-mini-fab color="primary" (click)="fileInput.click()">
                                    <mat-icon>attach_file</mat-icon>
                                    <input #fileInput type="file" multiple="multiple" (change)="getLicenseFileDetails($event)"
                                        style="display:none;" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" style="margin-bottom: 0px !important;">
                            <div class="col-md-12" style="margin-top: 10px;" *ngFor="let file of licenseFiles">
                                <div class="row">
                                    <div class="col-md-10">
                                        {{file.name}} - {{file.type}} - {{file.size}}
                                    </div>
                                    <div class="col-md-2">
                                        <button type="button"mat-mini-fab color="warn" (click)="deleteLicenseFile(file)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="col-md-12" style="margin-bottom: 0px !important;">
                        <div class="row">
                            <div class="col-md-10">
                                <mat-label>Upload selfie with driving license
                                    <span style="color: red;">* </span>
                                </mat-label>
                            </div>
                            <div class="col-md-2">
                                <button type="button" mat-mini-fab color="primary" (click)="selfyFileInput.click()">
                                    <mat-icon>attach_file</mat-icon>
                                    <input #selfyFileInput type="file" multiple="multiple" (change)="getSelfyFileDetails($event)"
                                        style="display:none;" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" style="margin-bottom: 0px !important;">
                            <div class="col-md-12" style="margin-top: 10px;" *ngFor="let file of selfyFiles">
                                <div class="row">
                                    <div class="col-md-10">
                                        {{file.name}} - {{file.type}} - {{file.size}}
                                    </div>
                                    <div class="col-md-2">
                                        <button type="button"mat-mini-fab color="warn" (click)="deleteSelfyFile(file)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div class="col-md-12">
                        <button mat-raised-button color="primary" type="submit"
                            [disabled]="(!patientStateForm.valid || licenseFiles.length <= 0 || selfyFiles.length <= 0 || submitProcessFlag)">
                            <mat-icon *ngIf="submitProcessFlag">
                                <mat-spinner diameter="20">
                                </mat-spinner>
                            </mat-icon>
                            Proceed
                        </button>
                    </div>
                </div>
                
            </form>
        </div>
    </div>
</div>