import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdminServicesService } from '../../api-services/admin-services/admin-services.service';
//import { AthenticationService } from '../../../service/athentication.service';
import { AlertDialogComponent } from '../../admin/dialog/alert-dialog/alert-dialog.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-payment-address',
  templateUrl: './payment-address.component.html',
  styleUrls: ['./payment-address.component.sass']
})
export class PaymentAddressComponent implements OnInit {
  
  disableInEditMode: boolean = false;
  submitButtonText: string = "Submit";
  formHeading: string = "Update Patient Delivery Details";
  userTypes: any;

 editUserForm = new FormGroup({
  PatientFirstName: new FormControl('', [Validators.required]),
  PatientLastName: new FormControl('', [Validators.required]),
  PatientEmail: new FormControl('', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
  PatientPhone: new FormControl('',),
  PatientAddress: new FormControl(''),
  PatientCity: new FormControl(''),
  PatientState: new FormControl(''),
  PatientZip: new FormControl(''),
  PatientGender: new FormControl(''),
  PatientDob: new FormControl('', [Validators.required]),
  PatientHeight: new FormControl(''),
  PatientWeight: new FormControl(''),
  PatientEnrollmentDate: new FormControl(''),
});



 patientId:number = 0;
 pickerDOB:string = '';
 pickerEnrollmentDate:string = '';
 selectedGender:string = '';



 genders:any = [
  {"id":"NotSelected","genderName":"Not Selected"},
  {"id":"Male","genderName":"Male"},
  {"id":"Female","genderName":"Female"},
];




  constructor(@Inject(MAT_DIALOG_DATA) public data:any,public adminService: AdminServicesService, private route: Router, 
  // private athenticationService: AthenticationService, 
  private dialog: MatDialog, public dialogRef: MatDialogRef<any>, private datePipe: DatePipe) { }

  ngOnInit(): void {
    debugger;
    this.dialogRef.updateSize("70%");
    this.dialogRef.disableClose = true;


    if(this.data != null && this.data.selectedRowData != null && this.data.selectedRowData.patientId > 0) {
      this.disableInEditMode = true;
      this.submitButtonText = "Update";

      this.patientId = this.data.selectedRowData.patientId;
      this.selectedGender = this.data.selectedRowData.gender;

      this.editUserForm.setValue({
        PatientFirstName: this.data.selectedRowData.firstName,
        PatientLastName: this.data.selectedRowData.lastName,
        PatientEmail: this.data.selectedRowData.email,
        PatientPhone: this.data.selectedRowData.phone,
        PatientAddress: this.data.selectedRowData.address,
        PatientCity: this.data.selectedRowData.city,
        PatientState: this.data.selectedRowData.state,
        PatientZip: this.data.selectedRowData.zip,
        PatientGender: this.data.selectedRowData.gender,
        PatientDob: new Date(this.datePipe.transform(this.data.selectedRowData.dob, "MM/dd/yyyy")).toString(),
        PatientHeight: this.data.selectedRowData.height,
        PatientWeight: this.data.selectedRowData.weight,
        PatientEnrollmentDate: this.data.selectedRowData.enrollmentDate,
     });

    }
  }

  submitUserForm() {
      
    let form = this.editUserForm;
    
    let PSId = 0;
    let UId = 0;
    let UserOperation = 1;
    let operationMessage = "Patient Delivery Details Updated Successfully!";
    

    let patientDeliveryAddressModel = {
      patientId: this.patientId,
      firstName: form.value['PatientFirstName'],
      lastName: form.value['PatientLastName'],
      email : form.value['PatientEmail'],      
      phone : form.value['PatientPhone'],    
      address : form.value['PatientAddress'],    
      city :form.value['PatientCity'],    
      state : form.value['PatientState'],    
      zip : form.value['PatientZip'],    
      gender : this.selectedGender, 
      dob : this.datePipe.transform(form.value['PatientDob'], "yyyy-MM-dd"),  
      height : form.value['PatientHeight'],
      weight: form.value['PatientWeight'],
      enrollmentDate : form.value['PatientEnrollmentDate']
    };
    
  //  this.adminService.UpdatePatientDeliveryAddressDetails(patientDeliveryAddressModel).subscribe(data => {
  this.adminService.UpdatePatientDetails(patientDeliveryAddressModel).subscribe(data => {
           
      if(data.data == 1){
        
        
        this.closeDialog();
        this.openAlertDialog(operationMessage);
      }
      else {
        this.openAlertDialog('Error occurred during update operation');
      }
    }, 
    //error => {

    //}
   );

  }


  openAlertDialog(alertMessage: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: alertMessage,
        buttonText: {
          cancel: 'OK'
        }
      },
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  disallowSpace(event) {
    return event.keyCode != 32 ? event:event.preventDefault();
  }

  

  public checkError = (controlName: string, errorName: string) => {
    return this.editUserForm.controls[controlName].hasError(errorName);
  }

}
