<div class="container">
    <div class="card">
        <div class="body">
            <div class="clearfix"></div>
            <br />

            <div class="row">
                <div class="col-md-12">
                    <mat-card>
                        <mat-card-content>
                            <mat-card-title>
                                <label>Payment has been done successfully. You will get invoice e-mail in to your mail
                                    box. Thank you for visiting us. We will get back to you soon.</label>
                            </mat-card-title>

                            <mat-card-subtitle>
                                <label>You can also download the invoice for further referende.</label> <br />
                                <div *ngIf="beforePaymentSection">
                                    <button style="margin-top: 10px; margin-bottom: 10px;" mat-raised-button
                                        [disabled]="isDownloadInProgress" color="primary" type="button"
                                        (click)="downloadInvoice()">
                                        <mat-icon *ngIf="isDownloadInProgress">
                                            <mat-spinner diameter="20">
                                            </mat-spinner>
                                        </mat-icon>

                                        Download Invoice
                                    </button>
                                </div>
                                <div>
                                    <h6>
                                        For updates and questions, please visit <a
                                            href="http://updates.frontlinemds.com">updates.frontlinemeds.com</a>.
                                    </h6>
                                    <h6 style="font-size: 33px;color: red;">
                                        <a style="font-size: 31px !important;"
                                            href="https://marketplace.drstellamd.com/"> Click here </a> to
                                        order your recommended vitamins
                                    </h6>
                                    <h6>Dr. Stella MD's Team.</h6>
                                </div>

                            </mat-card-subtitle>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="row">
                <h5 style="color: red;">
                    <strong class="blink_text">** If you getting any difficulties to filling up the form. Please mail us
                    </strong>
                    <a style="font-weight: bold; font-size: 18px !important;"
                        href="mailto:patienthelpdesk@drstellamd.com">patienthelpdesk&#64;drstellamd.com</a>
                        <br/>
                        <a style="font-weight: bold; font-size: 18px !important;" href="tel:+832-808-5574">Telephone - 832-808-5574</a>
                </h5>
            </div>
            <div class="row">
                <div class="col-sm-6 align-text-bottom"><img src="assets/img/drstella_logo.png" height="50px"></div>
                <div class="col-sm-6 text-right poweredby align-text-bottom" *ngIf="false">Powered by :<a
                        href="https://www.gracesoft.com" target="_blank"><img src="assets/img/gracesoft_logo.png"
                            height="70px"></a></div>
            </div>

        </div>
    </div>
</div>