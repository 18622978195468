import { DatePipe } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/admin/dialog/alert-dialog/alert-dialog.component';
import { SurveyServicesService } from 'src/app/api-services/survey-services/survey-services.service';

@Component({
  selector: 'app-acute-care-state',
  templateUrl: './acute-care-state.component.html',
  styleUrls: ['./acute-care-state.component.scss']
})
export class AcuteCareStateComponent implements OnInit {

  stateName: string = "";

  licenseFiles: string[] = [];
  selfyFiles: string[] = [];

  @ViewChild("fileInput") drivingLicenseInput: ElementRef;
  @ViewChild("selfyFileInput") selfyInput: ElementRef;

  submitProcessFlag: boolean = false;


  patientStateForm = new FormGroup({
    state: new FormControl('', [Validators.required]),
    //drivingLicense: new FormControl('', [Validators.required]),
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: any[], 
  private dialog: MatDialog, 
  public dialogRef: MatDialogRef<any>, 
  private surveyService: SurveyServicesService,
  private router: Router) { }

  ngOnInit(): void {
    this.dialogRef.updateSize("500px", "500px");
    this.dialogRef.disableClose = true;
    this.loadPatientStates();
  }

  submitPatientStateForm() {
    debugger;
    let stateId = this.patientStateForm.value["state"];
    if (stateId != null) {
      this.submitProcessFlag = true;
      const frmData = new FormData();

      for (var i = 0; i < this.licenseFiles.length; i++) {
        frmData.append("identityFiles", this.licenseFiles[i]);
        frmData.append("identityFiles", this.selfyFiles[i]);
      }
      
      this.surveyService.identityfileUpload(frmData).subscribe(res => {
        if(res && res.data) {
          const savedFiles = JSON.stringify(res.data);
          window.sessionStorage.setItem("_acuteCareSavedFiles", savedFiles);
          console.log(res.data);
          this.dialogRef.close({ isSubmit: true, stateName: this.stateName });
        }
        else {
          this.submitProcessFlag = false;
          this.openAlertDialog(res.error.errorMessage);
        }
      });
    }
    else {
      this.openAlertDialog("Please select State.");
    }
  }

  loadPatientStates() {
    this.surveyService.getAcuteCarePatientState().subscribe(res => {
      if(res && res.data) {
        this.data = res.data
      }
      else {
        console.log("patient state not loaded from db.");
      }
    });
  }

  openAlertDialog(alertMessage: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: alertMessage,
        buttonText: {
          cancel: "OK",
        },
      },
    });
  }

  stateChange(event) {
    console.log(event.source.triggerValue);
    this.stateName = event.source.triggerValue;
  }

  getLicenseFileDetails(event) {
    debugger;
    if(this.licenseFiles && this.licenseFiles.length < 1) {
      for (var i = 0; i < event.target.files.length; i++) {
        this.licenseFiles.push(event.target.files[i]);
      }
      console.log(this.licenseFiles)
    }
    else {
      this.openAlertDialog("File already been selected. Please remove previous file for now file upload.");
    }
    
  }

  getSelfyFileDetails(event) {
    debugger;
    if(this.selfyFiles && this.selfyFiles.length < 1) {
      for (var i = 0; i < event.target.files.length; i++) {
        this.selfyFiles.push(event.target.files[i]);
      }
      console.log(this.selfyFiles)
    }
    else {
      this.openAlertDialog("File already been selected. Please remove previous file for now file upload.");
    }
    
  }

  deleteLicenseFile(file: any) {
    const index = this.licenseFiles.indexOf(file);
    if (index > -1) { // only splice array when item is found
      this.licenseFiles.splice(index, 1); // 2nd parameter means remove one item only
    }
    this.drivingLicenseInput.nativeElement.value = "";
  }

  deleteSelfyFile(file: any) {
    const index = this.selfyFiles.indexOf(file);
    if (index > -1) { // only splice array when item is found
      this.selfyFiles.splice(index, 1); // 2nd parameter means remove one item only
    }
    this.selfyInput.nativeElement.value = "";
  }

  acuteCareCloseClick(event) {
    this.dialogRef.close({ isSubmit: false, stateName: this.stateName });

  }

  public checkError = (controlName: string, errorName: string) => {
    return this.patientStateForm.controls[controlName].hasError(errorName);
  }

}
