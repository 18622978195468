import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SurveyServicesService } from 'src/app/api-services/survey-services/survey-services.service';

@Component({
  selector: 'app-affiliate-login',
  templateUrl: './affiliate-login.component.html',
  styleUrls: ['./affiliate-login.component.sass']
})
export class AffiliateLoginComponent implements OnInit {

  _SessionKey: string = "OTP";
  initialView: boolean = true;
  affiliateMarketLoginForm = new FormGroup({
    email: new FormControl("", [Validators.required])
  });

  validateOTPForm = new FormGroup({
    OTP: new FormControl("", [Validators.required])
  });

  constructor(private appService: SurveyServicesService, private router: Router) { }

  ngOnInit(): void {
    this.sessionGet();
  }

  submitAffiliateMarketLogin() {
    let form = this.affiliateMarketLoginForm;
    let email: string = form.value["email"]
    this.appService.getAffiliateMarketingLoginOTP(email).subscribe(res => {
      debugger;
      if(res && res.data) {
        let OTP: string = res.data;
        this.sessionSet(this._SessionKey, OTP, 1);
        this.initialView = false;
        window.sessionStorage.setItem("_email", email);
      }
    });
    
  }

  submitOTP() {
    let form = this.validateOTPForm;
    let OTP: string = form.value["OTP"];
    let storedValue = this.sessionGet();
    if(OTP == storedValue) {

      this.router.navigate(['/affiliate-market/dashboard']);
    }
  }

  sessionGet() {
    let stringValue = window.sessionStorage.getItem(this._SessionKey)
    if (stringValue !== null) {
      let value = JSON.parse(stringValue)
        let expirationDate = new Date(value.expirationDate)
        if (expirationDate > new Date()) {
          return value.value
        } else {
          window.sessionStorage.removeItem(this._SessionKey);
          alert("OTP got expired. Please try again.");
        }
    }
    return null
  }

  sessionSet(key, value, expirationInMin = 10) {
    let expirationDate = new Date(new Date().getTime() + (60000 * expirationInMin))
    let newValue = {
    value: value,
    expirationDate: expirationDate.toISOString()
  }
  window.sessionStorage.setItem(key, JSON.stringify(newValue))
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.affiliateMarketLoginForm.controls[controlName].hasError(errorName);
  };

  public checkErrorOTP = (controlName: string, errorName: string) => {
    return this.validateOTPForm.controls[controlName].hasError(errorName);
  };

}
