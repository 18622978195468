import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AlertDialogComponent } from "src/app/admin/dialog/alert-dialog/alert-dialog.component";
import { SharedComponentService } from "src/app/api-services/shared-component-services/shared-component.service";
import {
  ExistingSurveyPatientInfo,
  patientSearchModel,
  SurveyDetails,
  SurveySubmissionDetails,
  IntakeFormLogModel,
} from "src/app/Models/survey-details";
import { SurveyServicesService } from "../../api-services/survey-services/survey-services.service";
import { AdminServicesService } from "../../api-services/admin-services/admin-services.service";
import { ConfirmDialogComponent } from "src/app/admin/dialog/confirm-dialog/confirm-dialog.component";

import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "app-selector",
  templateUrl: "./selector.component.html",
  styleUrls: ["./selector.component.sass"],
})
export class SelectorComponent implements OnInit {
  surveyDetails: SurveyDetails[];
  surveyTypes: string[];
  procedureRates: number[] = [];
  initialDisplay: boolean = true;
  selectedSurveyDetailsId: number;
  faqHtml: SafeHtml = "";

  existingPatientInfo: ExistingSurveyPatientInfo;
  surveySubmissionDetails: SurveySubmissionDetails;
  ipAddress: string;

  formDataCaptureString: string = "";

  existingPatientForm = new FormGroup({
    firstName: new FormControl("", [Validators.required]),
    dob: new FormControl(""),
    email: new FormControl("", [Validators.required]),
  });

  constructor(
    private surveyServices: SurveyServicesService,
    private router: Router,
    public adminService: AdminServicesService,
    private sharedService: SharedComponentService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.freshLoad();
    this.openAlertDialog(
      "Due to push back from mainstream pharmacies all Hydroxychloroquine and Ivermectin will be paid for on this site and shipped to you directly by MOM and POP pharmacies nationwide. For sick patients you will need to enter local pharmacy number so we can call in the rest of your medication."
    );
    this.getClientIp();
    this.surveyServices.getSurveyType().subscribe((response) => {
      if (response && response.data) {
        this.surveyDetails = response.data;
        console.log(this.surveyDetails);
        this.surveyTypes = [
          ...new Set(this.surveyDetails.map((item) => item.surveyTypeName)),
        ];
        this.procedureRates = [
          ...new Set(this.surveyDetails.map((item) => item.procedureRate)),
        ];
      } else {
        //implement any error prompt
      }
    });
  }

  freshLoad() {
    sessionStorage.removeItem("selectedSurvey");
    sessionStorage.removeItem("procedureRate");
    sessionStorage.removeItem("existingSubmissionId");
    sessionStorage.removeItem("existingPatientId");
    localStorage.removeItem("intakeFormEmail");
    sessionStorage.removeItem("patientAns");
    sessionStorage.removeItem("_isAcuteSurvey");

    sessionStorage.removeItem("_acuteCare");
    sessionStorage.removeItem("_acuteAnswer");
    sessionStorage.removeItem("_acuteAnswerDesc");
    sessionStorage.removeItem("_acuteCareStepsDetails");
    sessionStorage.removeItem("_criticalDataForPaymentPage");
    sessionStorage.removeItem("_AcuteCarePatientState");
  }

  getSurveyDetailsByType(selectedType: string): SurveyDetails[] {
    return this.surveyDetails.filter((f) => f.surveyTypeName == selectedType);
  }

  surveySelectorClick(surveyId: any): void {
    this.selectedSurveyDetailsId = surveyId;
    debugger;
    this.openRatePopup(surveyId);
    let selectedSurvey: SurveyDetails = this.surveyDetails.find((f) => f.surveyId == surveyId);
    //starts acute care process
    if (surveyId == 11 || surveyId == 12 || surveyId == 15 || surveyId == 16) {
      sessionStorage.setItem("AcuteCateSelectedSurvey", JSON.stringify(selectedSurvey));
      if (surveyId == 12) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            message:
              "Chronic Medicaion Refill services are not a substitute for in-person health care in all cases. The use of the services is not a replacement for your primary care physician consults. The Provider has the right to refuse to take responsibility for your care if the Provider makes a professional judgment that you are not a good candidate for this service, and you should instead seek medical attention from an in-person health care provider immediately. Anyone requesting a refill should follow up with their primary medical provider. By engaging in this consultation, you attest to your responsibility of maintaining in person care with your primary care provider and/or medical specialists.",
            buttonText: {
              ok: "Proceed",
              cancel: "",
            },
          },
        });
        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            const a = document.createElement('a');
            a.click();
            a.remove();
            this.router.navigate(["/acute-care"]);
          }
        });
      }
      else {
        this.router.navigate(["/acute-care"]);
      }

    }
    else {
      sessionStorage.setItem("selectedSurvey", JSON.stringify(selectedSurvey));
      sessionStorage.setItem("procedureRate", JSON.stringify(this.procedureRates));
      if (!selectedSurvey.hasRecordCheck) {
        var logGuid = ""; //localStorage.getItem("logGuid");
        if (logGuid == null || logGuid == "") logGuid = "";
        else localStorage.removeItem("logGuid");

        this.formDataCaptureString = "";
        var tempFormJson =
          '{ "dob" : "' +
          this.existingPatientForm.controls.dob.value +
          '" ,' +
          '"email": "' +
          this.existingPatientForm.controls.email.value +
          '" ,' +
          '"firstName" : "' +
          this.existingPatientForm.controls.firstName.value +
          '" }';


        this.formDataCaptureString = this.formDataCaptureString + tempFormJson;
        var logModel: IntakeFormLogModel = {
          logConnId: logGuid, //it should be blank
          userPhone: "",
          userEmail: "",
          userAction: selectedSurvey.surveyTypeName + "->" + selectedSurvey.surveyName + "-> New User Acquire Started",
          userActionCode: 200,
          ipAddress: localStorage.getItem("clientIp"),
          browserName: this.adminService.detectBrowserName(),
          browserVersion: this.adminService.detectBrowserVersion(),
          clientMetaData: this.adminService.detectClientDeviceMetadata(),
          formData: "Selector: " + this.formDataCaptureString,
        };

        this.insertIntakeFormLog(logModel);
        this.router.navigate(["/intakeform/steps"]);
      } else {
        //Entry to log
        debugger;
        var logGuid = ""; //localStorage.getItem("logGuid");
        if (logGuid == null || logGuid == "") logGuid = "";
        else localStorage.removeItem("logGuid");

        this.formDataCaptureString = "";

        var tempFormJson =
          '{ "dob" : "' +
          this.existingPatientForm.controls.dob.value +
          '" ,' +
          '"email": "' +
          this.existingPatientForm.controls.email.value +
          '" ,' +
          '"firstName" : "' +
          this.existingPatientForm.controls.firstName.value +
          '" }';
        this.formDataCaptureString = this.formDataCaptureString + tempFormJson;

        var logModel: IntakeFormLogModel = {
          logConnId: logGuid, //it should be blank
          userPhone: "",
          userEmail: "",
          userAction:
            selectedSurvey.surveyTypeName +
            "->" +
            selectedSurvey.surveyName +
            "-> New User Acquire Started",
          userActionCode: 200,
          ipAddress: localStorage.getItem("clientIp"),
          browserName: this.adminService.detectBrowserName(),
          browserVersion: this.adminService.detectBrowserVersion(),
          clientMetaData: this.adminService.detectClientDeviceMetadata(),
          formData: "Selector: " + this.formDataCaptureString,
        };
        this.insertIntakeFormLog(logModel);

        this.initialDisplay = false;
      }
    }

  }

  openRatePopup(surveyId: number) {
    // let procedureCharges: number = this.surveyDetails.find(
    //   (f) => f.surveyId == surveyId
    // ).procedureRate;
    // let message = "Consultation charges will be" + " $" + procedureCharges;
    // if (surveyId == 1) {
    //   this.openAlertDialog(message);
    // } else if (surveyId == 2) {
    //   this.openAlertDialog(message);
    // } else if (surveyId == 5) {
    //   this.openAlertDialog(message);
    // } else if (surveyId == 7) {
    //   this.openAlertDialog(message);
    // } else if (surveyId == 8) {
    //   message = "Refill charges" + " $" + procedureCharges;
    //   this.openAlertDialog(message);
    // }
    // else if(surveyId == 6) {

    // }
    if (surveyId == 10) {
      this.openAlertDialog("Please note! Treatment will not include Hydroxycloriquine or ivermectin.  It will include pharmacy medications like zpac, Prednisone, etc.");
    }
  }

  submitExistingPatientForm() {
    debugger;
    let form = this.existingPatientForm;
    let serveyDetails = JSON.parse(sessionStorage.getItem("selectedSurvey"));
    let name: string = form.value["firstName"].toString();
    let firstName: string = form.value["firstName"].toString();
    if (name != null && name.indexOf(" ") >= -1) {
      name = name.trim();
      firstName = name.split(" ")[0];
    }

    let existingPatientSearch: patientSearchModel = {
      dob: this.datePipe.transform(form.value["dob"], "yyyy-MM-dd"),
      email: form.value["email"],
      //firstName: firstName,
      firstName: name,
      surveyDetailsId: this.selectedSurveyDetailsId,
    };
    this.adminService
      .checkNonRefundedAccountByPatientEmail(existingPatientSearch.email)
      .subscribe((res) => {
        if (res && res.data) {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
              message:
                "Your email address has been blocked due to refund policy.",
              buttonText: {
                ok: "OK",
                cancel: "",
              },
            },
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            this.initialDisplay = true;
            //this.router.navigate(['/intakeform/selector']);
          });
        } else {
          if (
            serveyDetails != null &&
            (serveyDetails.surveyId == 2 || serveyDetails.surveyId == 7 || serveyDetails.surveyId == 10)
          ) {
            this.surveyServices
              .getSubmissionIdByFirstNameAndEmail(
                existingPatientSearch.firstName,
                existingPatientSearch.email
              )
              .subscribe((res) => {
                if (res.data != null) {
                  sessionStorage.setItem(
                    "existingSubmissionId",
                    res.data.submissionId
                  );
                  sessionStorage.setItem(
                    "existingPatientId",
                    res.data.patientId
                  );

                  //Entry to log
                  debugger;
                  var logGuid = ""; //localStorage.getItem("logGuid");
                  if (logGuid == null || logGuid == "") logGuid = "";
                  else localStorage.removeItem("logGuid");

                  this.formDataCaptureString = "";

                  var tempFormJson =
                    '{ "dob" : "' +
                    this.existingPatientForm.controls.dob.value +
                    '" ,' +
                    '"email": "' +
                    this.existingPatientForm.controls.email.value +
                    '" ,' +
                    '"firstName" : "' +
                    this.existingPatientForm.controls.firstName.value +
                    '" }';
                  this.formDataCaptureString =
                    this.formDataCaptureString + tempFormJson;

                  var logModel: IntakeFormLogModel = {
                    logConnId: logGuid, //it should be blank
                    userPhone: "",
                    userEmail: form.value["email"],
                    userAction:
                      serveyDetails.surveyTypeName +
                      "->" +
                      serveyDetails.surveyName +
                      "-> User found + Proceed",
                    userActionCode: 200,
                    ipAddress: localStorage.getItem("clientIp"),
                    browserName: this.adminService.detectBrowserName(),
                    browserVersion: this.adminService.detectBrowserVersion(),
                    clientMetaData:
                      this.adminService.detectClientDeviceMetadata(),
                    formData: "Selector: " + this.formDataCaptureString,
                  };
                  this.insertIntakeFormLog(logModel);
                  localStorage.setItem("intakeFormEmail", form.value["email"]);

                  this.router.navigate(["/intakeform/steps"]);
                } else {
                  alert(res.error.errorMessage);
                }
                debugger;
              });
          } else {
            debugger;
            this.surveyServices
              .createSubmissionAndGetExistingPatientDetails(
                existingPatientSearch
              )
              .subscribe((response) => {
                debugger;
                if (response.data != null) {

                  this.existingPatientInfo = response.data;
                  let enrollDate = new Date(this.existingPatientInfo.enrollmentDate);
                  let dateDiff = Math.abs(new Date().getTime() - enrollDate.getTime());
                  let diffDays = Math.ceil(dateDiff / (1000 * 3600 * 24));
                  if (existingPatientSearch.surveyDetailsId == 9 && diffDays > 30) {
                    alert("Your Follow up date is expired. Please select option of 'Already registered patient'");
                  }
                  else {
                    this.surveySubmissionDetails = {
                      submissionId: this.existingPatientInfo.submissionId,
                      patientDetails: {
                        patientId: this.existingPatientInfo.patientId,
                        firstName: this.existingPatientInfo.firstName,
                        middleName: this.existingPatientInfo.middleName,
                        lastName: this.existingPatientInfo.lastName,
                        phoneNo: this.existingPatientInfo.phoneNo,
                        email: this.existingPatientInfo.email,
                        address: this.existingPatientInfo.address,
                        state: this.existingPatientInfo.state,
                        city: this.existingPatientInfo.city,
                        zipCode: this.existingPatientInfo.zipCode,
                        zip: this.existingPatientInfo.zip,
                        gendar: this.existingPatientInfo.gendar,
                        gender: this.existingPatientInfo.gendar,
                        dob: this.existingPatientInfo.dob,
                        height: this.existingPatientInfo.height,
                        weight: this.existingPatientInfo.weight,
                      },
                      surveyDetails: JSON.parse(
                        sessionStorage.getItem("selectedSurvey")
                      ),
                    };
                    this.sharedService.setSurveySWubmissionDetails(
                      this.surveySubmissionDetails
                    );

                    debugger;
                    var logGuid = ""; //localStorage.getItem("logGuid");
                    if (logGuid == null || logGuid == "") logGuid = "";
                    else localStorage.removeItem("logGuid");

                    this.formDataCaptureString = "";

                    var tempFormJson =
                      '{ "dob" : "' +
                      this.existingPatientForm.controls.dob.value +
                      '" ,' +
                      '"email": "' +
                      this.existingPatientForm.controls.email.value +
                      '" ,' +
                      '"firstName" : "' +
                      this.existingPatientForm.controls.firstName.value +
                      '" }';
                    this.formDataCaptureString =
                      this.formDataCaptureString + tempFormJson;

                    var logModel: IntakeFormLogModel = {
                      logConnId: logGuid, //it should be blank
                      userPhone: "",
                      userEmail: form.value["email"],
                      userAction:
                        serveyDetails.surveyTypeName +
                        "->" +
                        serveyDetails.surveyName +
                        "-> User found + Proceed",
                      userActionCode: 200,
                      ipAddress: localStorage.getItem("clientIp"),
                      browserName: this.adminService.detectBrowserName(),
                      browserVersion: this.adminService.detectBrowserVersion(),
                      clientMetaData:
                        this.adminService.detectClientDeviceMetadata(),
                      formData: "Selector: " + this.formDataCaptureString,
                    };
                    this.insertIntakeFormLog(logModel);

                    this.router.navigate(["/intakeform/payment"]);

                  }
                  //if (this.existingPatientInfo.state != "Colorado") {

                  // } 
                  // else {
                  //   alert("Service not available for this area.");
                  // }
                  //Entry to log
                } else if (response.error != null) {
                  //Entry to log
                  debugger;
                  var logGuid = localStorage.getItem("logGuid");
                  if (logGuid == null || logGuid == "") logGuid = "";
                  else localStorage.removeItem("logGuid");

                  this.formDataCaptureString = "";

                  var tempFormJson =
                    '{ "dob" : "' +
                    this.existingPatientForm.controls.dob.value +
                    '" ,' +
                    '"email": "' +
                    this.existingPatientForm.controls.email.value +
                    '" ,' +
                    '"firstName" : "' +
                    this.existingPatientForm.controls.firstName.value +
                    '" }';
                  this.formDataCaptureString =
                    this.formDataCaptureString + tempFormJson;

                  var logModel: IntakeFormLogModel = {
                    logConnId: logGuid, //it should be blank
                    userPhone: "",
                    userEmail: form.value["email"],
                    userAction:
                      serveyDetails.surveyTypeName +
                      "->" +
                      serveyDetails.surveyName +
                      "-> User Not found",
                    userActionCode: 400,
                    ipAddress: localStorage.getItem("clientIp"),
                    browserName: this.adminService.detectBrowserName(),
                    browserVersion: this.adminService.detectBrowserVersion(),
                    clientMetaData:
                      this.adminService.detectClientDeviceMetadata(),
                    formData: "Selector: " + this.formDataCaptureString,
                  };
                  this.insertIntakeFormLog(logModel);

                  alert(response.error.errorMessage);
                } else {
                  //Entry to log
                  debugger;
                  var logGuid = localStorage.getItem("logGuid");
                  if (logGuid == null || logGuid == "") logGuid = "";
                  else localStorage.removeItem("logGuid");

                  this.formDataCaptureString = "";

                  var tempFormJson =
                    '{ "dob" : "' +
                    this.existingPatientForm.controls.dob.value +
                    '" ,' +
                    '"email": "' +
                    this.existingPatientForm.controls.email.value +
                    '" ,' +
                    '"firstName" : "' +
                    this.existingPatientForm.controls.firstName.value +
                    '" }';
                  this.formDataCaptureString =
                    this.formDataCaptureString + tempFormJson;

                  var logModel: IntakeFormLogModel = {
                    logConnId: logGuid, //it should be blank
                    userPhone: "",
                    userEmail: form.value["email"],
                    userAction:
                      serveyDetails.surveyTypeName +
                      "->" +
                      serveyDetails.surveyName +
                      "-> User Not found",
                    userActionCode: 400,
                    ipAddress: localStorage.getItem("clientIp"),
                    browserName: this.adminService.detectBrowserName(),
                    browserVersion: this.adminService.detectBrowserVersion(),
                    clientMetaData:
                      this.adminService.detectClientDeviceMetadata(),
                    formData: "Selector: " + this.formDataCaptureString,
                  };
                  this.insertIntakeFormLog(logModel);

                  alert("Patient not found.");
                }
              });
          }
        }
      });
  }

  openAlertDialog(alertMessage: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: alertMessage,
        buttonText: {
          cancel: "Proceed",
        },
      },
    });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.existingPatientForm.controls[controlName].hasError(errorName);
  };

  insertIntakeFormLog(logModel: IntakeFormLogModel) {
    debugger;
    this.adminService.InsertIntakeFormLog(logModel).subscribe(
      (data) => {
        //debugger;
        if (data.error == null) {
          localStorage.setItem("logGuid", JSON.stringify(data.data));
        } else {
          //error in log
        }
      }
      //error => {

      //}
    );
  }

  getClientIp() {
    this.adminService.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
      localStorage.setItem("clientIp", this.ipAddress);
    });
  }

  LoadFAQ() {
    // this.http.get('https://kissht.com/',{responseType:'text'}).subscribe(res=>{
    //   this.faqHtml = this.sanitizer.bypassSecurityTrustHtml(res);
    window.open("https://telehealth.drstellamd.com/assets/faq.html", "_blank");
    //});
  }
}
