<section class="content">
    <div class="container-fluid">
        <div class="row clearfix">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div class="card">
                    <div class="header">
                        <p><b>Marketing Details</b></p>
                    </div>
                    <div class="body">
                        <!-- Add content here -->
                        <div class="row">
                            <div class="col-md-12">
                                <mat-card>
                                    <div class="row">
                                        <mat-form-field class="col-md-2">
                                            <mat-label>Choose start date</mat-label>
    
                                            <input matInput [matDatepicker]="picker" [(ngModel)]="startDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
    
                                        </mat-form-field>
    
    
                                        <mat-form-field class="col-md-2">
                                            <mat-label>Choose end date</mat-label>
    
                                            <input matInput [matDatepicker]="picker2" [(ngModel)]="endDate"
                                                [min]="startDate">
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker #picker2></mat-datepicker>
    
                                        </mat-form-field>
                                        <div class="col-md-5 align-bottom">
                                            <div class="row">
                                                <div class="col-md-2" style="padding-top: 8px;">
                                                    <button mat-raised-button color="primary"
                                                        (click)="searchRecord()">Search</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div style="text-align: right;">
                                              <button mat-button mat-raised-button color="primary" [disabled]="exportButtonDisabled"
                                                (click)="exportexcel()">Export To Excel</button>
                                            </div>
                                          </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-12" class="table-responsive" style="height: 700px;">
                                            <table mat-table class="mat-elevation-z8" [dataSource]="dataSource"
                                                style="width:100%;">
                                                <!-- <ng-container matColumnDef="promoterEmail">
                                                    <th mat-header-cell *matHeaderCellDef style="width:20%">Email</th>
                                                    <td mat-cell *matCellDef="let element"> {{element.promoterEmail}}</td>
                                                    <td mat-footer-cell *matFooterCellDef></td>
                                                </ng-container> -->

                                                <ng-container matColumnDef="applicationName">
                                                    <th mat-header-cell *matHeaderCellDef
                                                        style="width:20%; padding-right: 20px;">Application</th>
                                                    <td mat-cell *matCellDef="let element"
                                                        style="width:20%; padding-right: 20px;">
                                                        {{element.applicationName}} </td>
                                                        <td mat-footer-cell *matFooterCellDef></td>
                                                </ng-container>
                                                <ng-container matColumnDef="promoName">
                                                    <th mat-header-cell *matHeaderCellDef style="width:20%">Promo Name
                                                    </th>
                                                    <td mat-cell *matCellDef="let element"> {{element.promoName}} </td>
                                                    <td mat-footer-cell *matFooterCellDef>Total</td>
                                                </ng-container>
                                                <ng-container matColumnDef="amount">
                                                    <th mat-header-cell *matHeaderCellDef style="width:20%">Total</th>
                                                    <td mat-cell *matCellDef="let element"> {{element.amount | currency}} </td>
                                                    <td mat-footer-cell *matFooterCellDef>{{ totalAmount | currency }}</td>
                                                </ng-container>
                                                <!-- <ng-container matColumnDef="period">
                                                    <th mat-header-cell *matHeaderCellDef style="width:20%">Period</th>
                                                    <td mat-cell *matCellDef="let element"> {{element.startDate}} -
                                                        {{element.endDate}} </td>
                                                        <td mat-footer-cell *matFooterCellDef></td>
                                                </ng-container> -->
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"
                          class="example-first-footer-row" style="background-color: lightgray;"></tr>
                                            </table>
                                        </div>
                                        <mat-paginator [pageSizeOptions]="[25, 50, 100, 200]"
                                            showFirstLastButtons></mat-paginator>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>