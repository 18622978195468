import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ServiceSubscription, SubcribeDetailModel, SurveyMedicine, SurveySubmissionDetails } from 'src/app/Models/survey-details';
import { SurveyServicesService } from 'src/app/api-services/survey-services/survey-services.service';
import { SurveyDetails, SurveyStep, IntakeFormLogModel, ChildDisplay } from 'src/app/Models/survey-details';
import { SharedComponentService } from 'src/app/api-services/shared-component-services/shared-component.service';

@Component({
  selector: 'app-subscribefreeservices',
  templateUrl: './subscribefreeservices.component.html',
  styleUrls: ['./subscribefreeservices.component.sass']
})
export class SubscribefreeservicesComponent implements OnInit  {
 
  formGroupProductCheckOutAddress: FormGroup = new FormGroup({});   //This form group will capture all the data for address
  
  isBillingDetailsReadonly: boolean = false;
  
  productList: ServiceSubscription[] = [];
  subscriptionModel: SubcribeDetailModel= {} as SubcribeDetailModel;
  shipmentCharge: number = 0;
  isPromocodeButtonDisabled: boolean = true;
  discountedPercent: number = 0.00;
  discountedPrice: number = 0.00;
  promoCodeInvalidLabel: string = "";
  
  isCartEmpty: boolean = false;
  totalPriceWithShippingAndDiscount: number = 0.00;
  discountAmountShow: string = "";
  isAutoshipProductPayment: boolean = false;
  isNormalProductPayment: boolean = false;

  supplementProductAmount: number = 0;

  isPaymentConditionSelected: boolean = false;
  isSubscriptionRelatedProductPresent: boolean = false;

  //This click event subcription will receive event message / trigger when "check out" clicked at the header component
  clickEventsubscription_UpdateProductCheckOutAmountValue: ServiceSubscription;
  surveySubmissionDetails: SurveySubmissionDetails;
  SubcribeDetailModel: {
    Action: number; ServiceTypeId: any; FirstName: any; LastName: any; Email: any; PhoneNo: any; ShippingAddress1: any; ShippingAddress2: any; ShippingCity: any; ShippingState: any; ShippingZipCode: any; DeliveryAddress1: any; DeliveryAddress2: string; // Assuming it's always empty
    DeliveryCity: any; DeliveryState: any; DeliveryZipCode: any; DeliveryAgencyId: number; // Default value or fetch from a source
    DeliveryAgencyName: string; // Default value or fetch from a source
    SubscriptionCheck: boolean; // Default value or fetch from a source
    UerPaymentCount: number; // Default value or fetch from a source
  };
  surveyMedicines: SurveyMedicine[] = [];

  constructor(
    private fBuilder: FormBuilder
    , private router: Router
    , public datepipe: DatePipe
    , private dialog: MatDialog
    , private _snackBar: MatSnackBar
    ,private route: ActivatedRoute
    ,private surveyServices: SurveyServicesService
    ,private sharedService: SharedComponentService
  ) {

    //This will restrict only Cart Check-Out Navigation to Payment CheckOut Page
    this.SetupInitialParameters();

    
  }

  ngOnInit(): void {

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

   
  }

  SetupInitialParameters() {
    let currentUrl: string = window.location.href;
    //This will setup form controls
    this.SetupFormControls();

  }

  SetupFormControls() {
    this.formGroupProductCheckOutAddress
      = this.fBuilder.group({
       
        ShippingDetailsFirstName: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        ShippingDetailsLastName: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        ShippingDetailsEmail: new FormControl('', [
          Validators.required,
          Validators.email,
          Validators.maxLength(100),
        ]),
        ShippingDetailsPhoneNo: new FormControl('', [
          Validators.required,
          Validators.maxLength(20),
        ]),
        ShippingDetailsShippingAddress1: new FormControl('', [
          Validators.required,
          Validators.maxLength(200),
        ]),
        ShippingDetailsShippingAddress2: new FormControl('', [
          Validators.maxLength(200),
        ]),
        ShippingDetailsShippingCity: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        ShippingDetailsShippingState: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        ShippingDetailsShippingCountry: new FormControl('', [
          Validators.required
        ]),
        ShippingDetailsZipcode: new FormControl('', [
          Validators.required,
          Validators.maxLength(10),
        ]),
        FindAboutUs: new FormControl('', Validators.maxLength(300)),
        BillingDetailIsSameAsShippingAddress: false,

        BillingDetailsBillingAddress: new FormControl('', [
          Validators.required,
          Validators.maxLength(401),
        ]),
        BillingDetailsCity: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        BillingDetailsState: new FormControl('', [
          Validators.required,
          Validators.maxLength(50),
        ]),
        BillingDetailsZipcode: new FormControl('', [
          Validators.required,
          Validators.maxLength(10),
        ]),

      });
  }

  ReturnToSubscription() {
    //If there is any autoship item list then that will be cleared
   
    window.location.href = '/intakeform/Surveysubscription';
  }



  //This will be used when we toggle to duplicate address
  checkToggle() {
    var billingAddress = "";
    var billingCity = "";
    var billingState = "";
    var billingZipCode = "";

    if (this.formGroupProductCheckOutAddress.value["BillingDetailIsSameAsShippingAddress"] == true) {

      //Set Value
      billingAddress = this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingAddress1"] + ","
        + this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingAddress2"];
      billingCity = this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingCity"];
      billingState = this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingState"];
      billingZipCode = this.formGroupProductCheckOutAddress.value["ShippingDetailsZipcode"];

      //Disable Control
      this.isBillingDetailsReadonly = true;
    }
    else {
      //Enable Control
      this.isBillingDetailsReadonly = false;

    }
    this.formGroupProductCheckOutAddress.get('BillingDetailsBillingAddress')?.setValue(billingAddress);
    this.formGroupProductCheckOutAddress.get("BillingDetailsCity")?.setValue(billingCity);
    this.formGroupProductCheckOutAddress.get("BillingDetailsState")?.setValue(billingState);
    this.formGroupProductCheckOutAddress.get("BillingDetailsZipcode")?.setValue(billingZipCode);
  }

  //Send Payload to BE and make payment
  MakeSubscription() {
    // Extract form values
    const firstName = this.formGroupProductCheckOutAddress.value["ShippingDetailsFirstName"];
    const lastName = this.formGroupProductCheckOutAddress.value["ShippingDetailsLastName"];
    const email = this.formGroupProductCheckOutAddress.value["ShippingDetailsEmail"];
    const phoneNo = this.formGroupProductCheckOutAddress.value["ShippingDetailsPhoneNo"];
    const shippingAddress1 = this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingAddress1"];
    const shippingAddress2 = this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingAddress2"];
    const shippingCity = this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingCity"];
    const shippingState = this.formGroupProductCheckOutAddress.value["ShippingDetailsShippingState"];
    const shippingZipCode = this.formGroupProductCheckOutAddress.value["ShippingDetailsZipcode"];
    const deliveryAddress1 = this.formGroupProductCheckOutAddress.value["BillingDetailsBillingAddress"];
    const deliveryCity = this.formGroupProductCheckOutAddress.value["BillingDetailsCity"];
    const deliveryState = this.formGroupProductCheckOutAddress.value["BillingDetailsState"];
    const deliveryZipCode = this.formGroupProductCheckOutAddress.value["BillingDetailsZipcode"];
    const findAboutUs = this.formGroupProductCheckOutAddress.value["FindAboutUs"];
  
    // Iterate over selected service subscription IDs
    this.SubcribeDetailModel = {
        Action: 1,
        ServiceTypeId: 0,
        FirstName: firstName,
        LastName: lastName,
        Email: email,
        PhoneNo: phoneNo,
        ShippingAddress1: shippingAddress1,
        ShippingAddress2: shippingAddress2,
        ShippingCity: shippingCity,
        ShippingState: shippingState,
        ShippingZipCode: shippingZipCode,
        DeliveryAddress1: deliveryAddress1,
        DeliveryAddress2: "", // Assuming it's always empty
        DeliveryCity: deliveryCity,
        DeliveryState: deliveryState,
        DeliveryZipCode: deliveryZipCode,
        
        DeliveryAgencyId: 0, // Default value or fetch from a source
        DeliveryAgencyName: '', // Default value or fetch from a source
        SubscriptionCheck: false, // Default value or fetch from a source
        UerPaymentCount: 0 // Default value or fetch from a source
      };
      debugger;
      this.surveySubmissionDetails = {
        submissionId: 0,
          patientDetails: {
            patientId: 0,
            firstName: '',
            middleName: '',
            lastName: '',
            phoneNo: '',
            email: '',
            address: '',
            state: '',
            city: '',
            zip: '',
            zipCode: '',
            gendar: '',
            gender: '',
            dob: '',
            height: '',
            weight: '',
          },
          surveyDetails: {
          surveyId: 0,
          surveyCode: 0,
          surveyName: '',
          surveyTypeName: '',
          procedureRate: 50,
          shippingCharge: 0,
          surveyTypeCode: '',
          hasRecordCheck: false,
          description: '',
          service_mode: '',
          created_at: new Date(),
          updated_at: new Date(),
        RefPatientStatus: 0,
        }
      }
      
      this.sharedService.setSurveySWubmissionDetails(this.surveySubmissionDetails);
      sessionStorage.setItem("_criticalDataForPaymentPage", JSON.stringify(this.surveySubmissionDetails));
      sessionStorage.setItem("selectedSurvey", JSON.stringify(this.surveySubmissionDetails.surveyDetails));
    this.router.navigate(['/intakeform/premiumpayment']);
      // this.surveyServices.subscribeService(subscriptionModel).subscribe({
      //   next: response => {
      //     console.log('Subscription successful for ServiceTypeId:', serviceTypeId, response);
      //   },
      //   error: err => {
      //     console.error('Subscription failed for ServiceTypeId:', serviceTypeId, err);
      //   }
      // });

    // this.openSnackBar("Subscription successful.", "Dismiss");
    // // Navigate to the next page after all requests are made
    // this.router.navigate(['/intakeform/Surveysubscription']);
  }
  

  ProceedToHomePage() {
    window.location.href = this.router['location']._platformLocation.location.origin;
  }

  ProceedToAutoShip() {
    window.location.href = '/autoship-order/';
  }

  DisabledPayNowActions() {
    this.openSnackBar("Please validate all the input data.", "Dismiss");
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  closeSnackBar() {
    this._snackBar.dismiss();
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.formGroupProductCheckOutAddress.controls[controlName].hasError(errorName);
  };

  DisallowSpace(event: any) {
    return event.keyCode != 32 ? event : event.preventDefault();
  }
//   confirmClick() {
    
// }
}
