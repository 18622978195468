<div class="wrapper">
  <mat-card class="container">
      <h3 class="text-center" style="color: #4043ac">
        Welcome to Dr Stella Immanuel MD
      </h3>
      <ng-container>
        <mat-card-title>Login</mat-card-title>
      </ng-container>

      <div class="row" *ngIf="initialView; else enter_OTP">
        <div class="col-sm-4">
            <form class="form"[formGroup]="affiliateMarketLoginForm" (ngSubmit)="submitAffiliateMarketLogin()" novalidate>
                <div class="row">
                    <div class="col-md-10">
                        <mat-form-field appearance="standard">
                            <mat-label>Email
                                <span style="color: red;">*</span>
                            </mat-label>
                            <input matInput formControlName="email" type="text">
                            <mat-error *ngIf="checkError('email', 'required')">Email required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2" style="margin-top: 15px;">
                        <button mat-raised-button [disabled]="!affiliateMarketLoginForm.valid" color="primary" type="submit">Get OTP</button>
                    </div>
                </div>
            </form>
          </div>
        </div>

        <ng-template #enter_OTP>
            <div class="col-sm-4">
            <form class="form"[formGroup]="validateOTPForm" (ngSubmit)="submitOTP()" novalidate>
                <div class="row">
                    <div class="col-md-10">
                        <mat-form-field appearance="standard">
                            <mat-label>OTP
                                <span style="color: red;">*</span>
                            </mat-label>
                            <input matInput formControlName="OTP" type="text">
                            <mat-error *ngIf="checkErrorOTP('OTP', 'required')">OTP required</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="col-md-2" style="margin-top: 15px;">
                        <button mat-raised-button [disabled]="!validateOTPForm.valid" color="primary"
                                    type="submit">Enter OTP</button>
                    </div>
                </div>
            </form>
        </div>
        </ng-template>
  </mat-card>
</div>
