<div class="wrapper">
    <mat-card class="container">
        <form class="form" #form="ngForm" novalidate>
            <h3 class="text-center" style="color: #4043ac;">Welcome to Telehealth Services </h3>
            <h3 class="text-center" style="color: #4043ac; margin-bottom: 25px;">with Dr Stella Immanuel MD </h3>
            <!-- <marquee direction="left" scrollamount="4" onmouseover="this.stop();" onmouseout="this.start();">
                <b>1.</b> ${{procedureRates[0]}} for prevention for Covid-19. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <b>2.</b> ${{procedureRates[1]}} for sick patients! Includes up to 3 follow up visits. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                <b>3.</b> ${{procedureRates[2]}} for refill medicines
            </marquee>
            <p class="text-center" style="margin-bottom: 0;">${{procedureRates[0]}} for prevention for Covid-19.</p>
            <p class="text-center" style="margin-bottom: 5px;">${{procedureRates[1]}} for sick patients! Includes up to 3 follow up visits.</p>
            <p class="text-center" style="margin-bottom: 15px;">${{procedureRates[2]}} for refill medicines</p> -->
            <ng-container>
                <div *ngIf="initialDisplay;else other_content">
                    <mat-card-title>Select Survey</mat-card-title>
                </div>
                <ng-template #other_content>
                    <mat-card-title>Enter Details</mat-card-title>
                </ng-template>
            </ng-container>
 
            <div class="row" *ngIf="initialDisplay"> 
                <div *ngFor="let type of surveyTypes" class="col-sm-4 text-center survey_type_box">
                    <div class="survey_type_inner_box">
                        <br>
                        <p>{{type}}</p>
                        <div *ngFor="let details of getSurveyDetailsByType(type)">
                            <button mat-raised-button color="primary" [innerHTML]="details.surveyName"
                                (click)="surveySelectorClick(details.surveyId)"></button>
                            <br><br>
                        </div>


                        <!-- <button mat-raised-button color="primary">Already registered patient</button>
                        <br><br> -->
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="!initialDisplay">
                <form [formGroup]="existingPatientForm" (ngSubmit)="submitExistingPatientForm()" style="width: 100%;">
                    <div class="col-md-12">
                        <h6>Please enter your details below :</h6>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field appearance="standard">
                                <mat-label>First Name
                                    <span style="color: red;">*</span>
                                </mat-label>
                                <input matInput formControlName="firstName" type="text">
                                <mat-error *ngIf="checkError('firstName', 'required')">Name is required</mat-error>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4" style="display: none;">
                            <mat-form-field appearance="standard">
                                <mat-label>Date of Birth
                                    <span style="color: red;">*</span>
                                </mat-label>
                                <input matInput type="text" formControlName="dob" [matDatepicker]="picker">
                                <!-- <mat-error *ngIf="checkError('dob', 'required')">Date of birth is required</mat-error> -->
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>

                        <div class="col-md-4">
                            <mat-form-field appearance="standard">
                                <mat-label>Email
                                    <span style="color: red;">*</span>
                                </mat-label>
                                <input matInput formControlName="email" type="text">
                                <mat-error *ngIf="checkError('email', 'required')">Email required</mat-error>
                            </mat-form-field>
                        </div>
                    </div>



                    <div class="row">
                        <div class="col-sm-6">
                            <button mat-raised-button (click)="initialDisplay = true" color="primary">Back</button>
                        </div>
                        <div class="col-sm-6" style="text-align: right;">
                            <button mat-raised-button [disabled]="!existingPatientForm.valid" color="primary"
                                type="submit">Search</button>
                            <!-- <button mat-raised-button color="primary" disabled>Please wait...</button> -->
                        </div>
                    </div>
                </form>
            </div>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <hr>
        </form>
        <mat-card-footer>
            <div class="row">
                <div class="col-md-6">
                    <h5 style="color: red;">
                        <strong class="blink_text">In case of difficulty in filling up the form, please email us at..</strong>
                        <h4>
                            <a style="font-weight: bold; font-size: 18px !important;" href="mailto:patienthelpdesk@drstellamd.com">patienthelpdesk&#64;drstellamd.com</a>
                            <br/>
                            <a style="font-weight: bold; font-size: 18px !important;" href="tel:+832-808-5574">Telephone - 832-808-5574</a>
                            <br>
                        </h4>
                    </h5>
                </div>
                <div class="col-md-6">
                    <div class="col-md-12" style=" text-align: right;">
                        <a style="font-weight: bold; font-size: 18px !important;"
                         href="javascript:void(0)" (click)="LoadFAQ()">FAQ</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 align-text-bottom"><img src="assets/img/drstella_logo.png" height="50px"></div>
                <div class="col-sm-6 text-right poweredby align-text-bottom" *ngIf="false">Powered by :<a
                        href="https://www.gracesoft.com" target="_blank"><img src="assets/img/gracesoft_logo.png"
                            height="70px"></a></div>
            </div>
        </mat-card-footer>


    </mat-card>
</div>
