import { NgModule } from '@angular/core';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { PageLoaderComponent } from './layout/page-loader/page-loader.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { RightSidebarComponent } from './layout/right-sidebar/right-sidebar.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { fakeBackendProvider } from './core/interceptor/fake-backend';
// import { ErrorInterceptor } from './core/interceptor/error.interceptor';
// import { JwtInterceptor } from './core/interceptor/jwt.interceptor';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from 'ngx-perfect-scrollbar';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ClickOutsideModule } from 'ng-click-outside';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { SelectorComponent } from './intakeform/selector/selector.component';
import { StepsComponent } from './intakeform/steps/steps.component';
import { PaymentComponent } from './intakeform/payment/payment.component';
import { JwtInterceptor } from './authentication/auth.interceptor';
import { PaymentAddressComponent } from './intakeform/payment-address/payment-address.component';
import { InvoiceComponent } from './intakeform/invoice/invoice.component';
import { PaymentConfirmComponent } from './intakeform/payment/payment-confirm/payment-confirm.component';
import { AffiliateLoginComponent } from './affiliate-marketing/affiliate-login/affiliate-login.component';
import { AffiliateMarketComponent } from './affiliate-marketing/affiliate-market/affiliate-market.component';
import { AcuteCareComponent } from './acute-care/acute-care.component';
import { SafeHtmlPipe } from './shared/safe-html.pipe';
import { AcuteCareStepsComponent } from './acute-care/acute-care-steps/acute-care-steps.component';
import { AcuteCareStateComponent } from './acute-care/acute-care-state/acute-care-state.component';
import { LineBreakPipe } from './shared/line-break.pipe';
import { RegularSampleMedicineComponent } from './acute-care/acute-care-steps/regular-sample-medicine/regular-sample-medicine.component';
import{ SurveysubscriptionComponent } from './intakeform/surveysubscription/surveysubscription.component'
import { SubscribefreeservicesComponent } from './intakeform/subscribefreeservices/subscribefreeservices.component';
import { PremiumpaymentComponent } from './intakeform/premiumpayment/premiumpayment.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    SelectorComponent,
    StepsComponent,
    PaymentComponent,
    PaymentAddressComponent,
    InvoiceComponent,
    PaymentConfirmComponent,
    AffiliateLoginComponent,
    AffiliateMarketComponent,
    AcuteCareComponent,
    SafeHtmlPipe,
    AcuteCareStepsComponent,
    AcuteCareStateComponent,
    LineBreakPipe,
    RegularSampleMedicineComponent,
    SurveysubscriptionComponent,
    SubscribefreeservicesComponent,
    PremiumpaymentComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    PerfectScrollbarModule,
    ClickOutsideModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),

    // core & shared
    CoreModule,
    SharedModule,
  ],
  providers: [DatePipe,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    fakeBackendProvider,
  ],
  entryComponents: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
