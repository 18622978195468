export enum Role {
  All = 0,
  Admin = 1,
  Doctor = 2,
  Staff = 3,
  Pharmacy = 4,
  Nurse = 5,
  promoStuff = 6
}

export interface UserMenuDetails {
  accessLevel: number;
  menuCode: number;
  parentCode: number;
  menuName: string;
  path: string;
  title: string;
  moduleName: string;
  iconType: string;
  icon: string;
  class: string;
  groupTitle: boolean;

}
