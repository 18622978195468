import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AcuteCareStepsComponent } from './acute-care/acute-care-steps/acute-care-steps.component';
import { AcuteCareComponent } from './acute-care/acute-care.component';
import { AffiliateLoginComponent } from './affiliate-marketing/affiliate-login/affiliate-login.component';
import { AffiliateMarketComponent } from './affiliate-marketing/affiliate-market/affiliate-market.component';
import { Page404Component } from './authentication/page404/page404.component';
import { AuthGuard } from './core/guard/auth.guard';
import { Role } from './core/models/role';
import { InvoiceComponent } from './intakeform/invoice/invoice.component';
import { PaymentComponent } from './intakeform/payment/payment.component';
import { SelectorComponent } from './intakeform/selector/selector.component';
import { StepsComponent } from './intakeform/steps/steps.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { SurveysubscriptionComponent } from './intakeform/surveysubscription/surveysubscription.component';
import { SubscribefreeservicesComponent } from './intakeform/subscribefreeservices/subscribefreeservices.component';
import { PremiumpaymentComponent } from './intakeform/premiumpayment/premiumpayment.component';


const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      role: [Role.Admin, Role.Doctor, Role.Staff, Role.Pharmacy, Role.promoStuff, Role.Nurse],
    },
    children: [
      { 
        path: '', redirectTo: '/authentication/signin', pathMatch: 'full' 
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        data: {
          role: Role.Admin,
        },
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      },
      {
        path: 'task',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./admin/task/task.module').then(
            (m) => m.TaskModule
          ),
      },
      {
        path: 'report',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./admin/report/report.module').then(
            (m)=> m.ReportModule
          ),
      },
      {
        path: 'submission',
        canActivate: [AuthGuard],
        data: {
          role: [Role.Admin, Role.Doctor, Role.Staff, Role.Pharmacy, Role.promoStuff, Role.Nurse],
        },
        loadChildren: () =>
          import('./admin/submission/submission.module').then(
            (m)=> m.SubmissionModule
          ),
          
      },
      {
        path: 'account',
        data: {
          role: [Role.Admin],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./admin/account/account.module').then(
            (m)=> m.AccountModule
          ),
      },
      {
        path: 'payroll',
        data: {
          role: [Role.Admin, Role.Doctor, Role.Staff, Role.Pharmacy, Role.promoStuff, Role.Nurse],
        },
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./admin/payroll/payroll.module').then(
            (m)=> m.PayrollModule
          ),
      },
    ],
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: 'intakeform',
    children: [
      { path: 'selector', component: SelectorComponent },
      { path: 'steps', component: StepsComponent },
      { path: 'payment', component: PaymentComponent },
      { path: 'payment-invoice', component: InvoiceComponent },
      { path: 'Surveysubscription', component: SurveysubscriptionComponent },
      {path: '', redirectTo: '/intakeform/selector', pathMatch: 'full'},
      { path: 'SubscribeFreeServices', component: SubscribefreeservicesComponent },
      { path: 'premiumpayment', component: PremiumpaymentComponent },
    ]
  },
  {
    path: 'affiliate-market',
    children: [
      {path: 'login', component: AffiliateLoginComponent },
      {path: 'dashboard', component: AffiliateMarketComponent },
      {path: '', redirectTo: '/affiliate-market/login', pathMatch: 'full'}
    ]
  },
  {
    path: 'acute-care',
    children: [
      {path: '', component: AcuteCareComponent },
      {path: 'steps', component: AcuteCareStepsComponent},
      {path: '', redirectTo: '/acute-care', pathMatch: 'full'}
    ]
  },
 
  { path: '**', component: Page404Component },

];
@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
