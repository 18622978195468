import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../core/models/user';

//import { AuthenticationService } from '@/_services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    //constructor(private authenticationService: AuthenticationService) { }
    constructor(){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
       // const currentUser = this.authenticationService.currentUserValue;
       // const isLoggedIn = currentUser && currentUser.token;
       // const isApiUrl = request.url.startsWith(config.apiUrl);
       //if (isLoggedIn && isApiUrl) {
           
        const re = "UserAuthenticate";
        let currentUser: User = JSON.parse(localStorage.getItem("currentUser"));
        if(currentUser) {
            let tokenItem: string = currentUser.token;
            if (request.url.search(re) === -1 ) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${tokenItem}`
                    }
                });
            }
        }
        
       // }

        return next.handle(request);
    }
}