<div class="row" style="max-height: 500px;">
    <div><h3 style="color: #cccccc;">{{formHeading}}</h3></div>
    <form [formGroup]="editUserForm" (ngSubmit)="submitUserForm()" style="width: 100%;">
        <mat-card>
            <div class="col-md-12 row">
                
                <div class="col-md-6">
                    <mat-form-field appearance="standard" style="width:80%">
                        <mat-label style="width: 100%;">First Name<span style="color: red;">*</span></mat-label>
                        <input matInput placeholder="Enter Patient First Name..." formControlName="PatientFirstName" style="width: 100%;">
                        <mat-error *ngIf="checkError('PatientFirstName', 'required')">Patient First Name is required</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="standard" style="width:80%">
                        <mat-label>Last Name<span style="color: red;">*</span></mat-label>
                        <input matInput placeholder="Enter Patient Last Name..." formControlName="PatientLastName">
                        <mat-error *ngIf="checkError('PatientLastName', 'required')">Patient Last Name is required</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="standard" style="width:80%">
                        <mat-label>Email<span style="color: red;">*</span></mat-label>
                        <input matInput placeholder="Enter Patient Email..." formControlName="PatientEmail">
                        <mat-error *ngIf="checkError('PatientEmail', 'required')">Patient Email is required</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="standard" style="width:80%">
                        <mat-label>Phone<span style="color: red;">*</span></mat-label>
                        <input matInput placeholder="Enter Patient Phone..." formControlName="PatientPhone">
                        <mat-error *ngIf="checkError('PatientPhone', 'required')">Patient Phone is required</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="standard" style="width:80%">
                        <mat-label>Address<span style="color: red;">*</span></mat-label>
                        <input matInput placeholder="Enter Patient Address..." formControlName="PatientAddress">
                        <mat-error *ngIf="checkError('PatientAddress', 'required')">Patient Address is required</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="standard" style="width:80%">
                        <mat-label>City<span style="color: red;">*</span></mat-label>
                        <input matInput placeholder="Enter Patient City..." formControlName="PatientCity">
                        <mat-error *ngIf="checkError('PatientCity', 'required')">Patient City is required</mat-error>
                    </mat-form-field>
                </div>
                
                <div class="col-md-6">
                    <mat-form-field appearance="standard" style="width:80%">
                        <mat-label>State<span style="color: red;">*</span></mat-label>
                        <input matInput placeholder="Enter Patient State..." formControlName="PatientState">
                        <mat-error *ngIf="checkError('PatientState', 'required')">Patient State is required</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="standard" style="width:80%">
                        <mat-label>Zip<span style="color: red;">*</span></mat-label>
                        <input matInput placeholder="Enter Patient Zip..." formControlName="PatientZip">
                        <mat-error *ngIf="checkError('PatientZip', 'required')">Patient Zip is required</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Gender<span style="color: red;">*</span></mat-label>
                        <mat-select [(value)]="selectedGender">
                            <mat-option *ngFor="let gender of genders" [value]="gender.id">
                                {{gender.genderName}}
                            </mat-option>
                        </mat-select>

                        <!-- <mat-error *ngIf="checkError('PatientGender', 'required')">Patient Gender is required</mat-error> -->
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="fill">
                        <mat-label>Choose Date of Birth</mat-label>
                        <input matInput [matDatepicker]="pickerDOBControl" formControlName="PatientDob">
                        <mat-datepicker-toggle matSuffix [for]="pickerDOBControl">
                        </mat-datepicker-toggle>
                        <mat-datepicker #pickerDOBControl></mat-datepicker>
                        <mat-error *ngIf="checkError('PatientDob', 'required')">Patient Date of Birth is required</mat-error>
                    </mat-form-field>

                </div>
                

                <div class="col-md-6">
                    <mat-form-field appearance="standard" style="width:80%">
                        <mat-label>Height<span style="color: red;">*</span></mat-label>
                        <input matInput placeholder="Enter Patient Height..." formControlName="PatientHeight">
                        <mat-error *ngIf="checkError('PatientHeight', 'required')">Patient Height is required</mat-error>
                    </mat-form-field>
                </div>


                <div class="col-md-6">
                    <mat-form-field appearance="standard" style="width:80%">
                        <mat-label>Weight<span style="color: red;">*</span></mat-label>
                        <input matInput placeholder="Enter Patient Weight..." formControlName="PatientWeight">
                        <mat-error *ngIf="checkError('PatientWeight', 'required')">Patient Weight is required</mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-6">
                    <mat-form-field appearance="standard" style="width:80%">
                        <mat-label>Enrollment Date (Read Only)<span style="color: red;">*</span></mat-label>
                        <input matInput placeholder="Enter Patient Enrollment Date..." formControlName="PatientEnrollmentDate" readonly>
                        <mat-error *ngIf="checkError('PatientEnrollmentDate', 'required')">Patient Last Name is required</mat-error>
                    </mat-form-field>
                </div>
            
                <div class="row">
                    <div class="col-md-12" style="text-align: right; margin-top: 25px;">
                        <button mat-raised-button color="primary" type="submit"
                            >{{submitButtonText}}</button>
                        <button mat-raised-button (click)="closeDialog()" style="margin-left:10px ;"
                            type="button">cancel</button>
                    </div>
                </div>


            </div>
        </mat-card>
    </form>
</div>
