<div class="pt-3 pb-2 pt-sm-3 pb-sm-3 pt-md-3 pb-md-3 autoship-body text-center">
    <img class="img-fluid mb-2 mb-md-0 pos-md-relative" src="../../../../assets/images/AutoShipMainImage.jpeg" alt="">
  </div>
  
  <div class="pt-1 pb-1 pl-2 pr-2 ml-2 mr-2 autoship-body text-center" style="width: 100%; padding-left:5px; padding-right:5px;">
    <span style="font-weight: 500;" class="pt-1 pb-1 pl-2 pr-2 autoship-body text-center">
      Supplements Subscription - any Service at $0 per month
    </span>
  </div>
  
  <div class="pt-1 pb-1 pl-2 pr-2 autoship-body text-center" style="width: 100%; padding-left:5px; padding-right:5px;">
    <span class="pt-1 pb-1 pl-2 pr-2 autoship-body text-center">
      **Please select any service out of listed and proceed.
    </span>
  </div>
  
  <form class="form">
    <div class="pt-3 pb-3 pt-sm-3 pb-sm-5 pt-md-3 pb-md-5 autoship-body">
      <div class="bootstrapped">
        <div class="container" style="height: 100%;">
          <div class="row text-left bg-color-img align-items-center">
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 wht-bg text-end">
              <button type="submit" *ngIf="(selectedServiceSubscriptionIds.length > 0)"
                      class="autoship" (click)="CheckOutSupplement()">Subscribe Now</button>
                  <!-- <button type="submit" *ngIf="!(productInternalOperation.varSelectedSupplements.length == 1)"
                      class="btn-shop-modal-autoship-disabled">Buy Now</button> -->
            </div>
  
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 wht-bg ">
              <div class="row row select-grid-row pt-3 pb-3" style="font-weight: 500;">
                <div class=" col-2">
                  <span></span>
                </div>
                <div class=" col-8 text-center">
                  <span class="">Service</span>
                </div>
              </div>
  
              <div *ngFor="let servicesubscription of ServiceSubscriptionList" class="row select-grid-row pt-3 pb-3">
                <div class="col-2">
                  <mat-checkbox id="{{servicesubscription.serviceTypeId}}" (change)="ServiceSubscriptionSelect(servicesubscription, $event)"
                                [checked]="selectedServiceSubscriptionIds.includes(servicesubscription.serviceTypeId)"
                                [disabled]="nonSelectedServiceSubscriptionIds.includes(servicesubscription.serviceTypeId)">
                  </mat-checkbox>
                </div>
                <div class=" col-8">
                  <div class="row">
                    <div class="col-8">{{servicesubscription.serviceType}}</div>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="col-12 col-sm-12 col-md-12 col-lg-12 wht-bg text-end pt-2">
              <button type="submit" *ngIf="(selectedServiceSubscriptionIds.length > 0)"
                      class="autoship" (click)="CheckOutSupplement()">Subscribe Now</button>
              <!-- <button type="submit" *ngIf="!(productInternalOperation.varSelectedSupplements.length == 1)"
                      class="btn-shop-modal-autoship-disabled">Buy Now</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  