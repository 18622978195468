import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lineBreak'
})
export class LineBreakPipe implements PipeTransform {

  transform(value: string, breakLength: number): string {
    debugger;
    var newString = "";
    for (var i = 0; i < value.length; i++) {
      newString = newString+value[i];
      if ((i+1) % breakLength == 0) {
        newString = newString+"<br/>";
      }
    }
    return newString;
  };
}
