import { Injectable } from '@angular/core';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Loginmodel } from 'src/app/Models/auth-model';
import { BehaviorSubject, catchError, map, Observable, of, throwError } from 'rxjs';
import { ServiceUrl } from '../service-url';
import { User } from 'src/app/core/models/user';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UserServicesService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private _url : string = "/assets/data/user.json";
  headers = new HttpHeaders().set('Content-Type','application/json')
  postData = '';
  adminService = this.dynamicServiceUrls.admin;
  constructor(private http : HttpClient,private dynamicServiceUrls: ServiceUrl,private router: Router) { 
    //debugger;//
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  getLoginUSerTypes(){
    return this.http.get(this._url);
  }
   
  loginCheck(loginmodel: Loginmodel){
    debugger;
    this.postData = JSON.stringify(loginmodel);
    localStorage.removeItem('currentUser');
    console.log('At Service Call (Login) : ' + this.postData);
    return this.http.post<any>(`${ this.dynamicServiceUrls.verification }UserAuthenticate`, this.postData,
    { headers: this.headers}).pipe(
      map(user => {
        if(user.data) {
          
          localStorage.setItem('currentUser', JSON.stringify(user.data));
          
        }
        return user;
        
      }),
      catchError(this.errorHandler));
  }

  

  errorHandler(error: HttpErrorResponse){
    let errorMessage = `Error code ${error.status}\nMessage: ${error.message}`;
    return throwError(errorMessage);
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  // login(username: string, password: string) {
  //   return this.http
  //     .post<any>(`${environment.apiUrl}/authenticate`, {
  //       username,
  //       password,
  //     })
  //     .pipe(
  //       map((user) => {
  //         // store user details and jwt token in local storage to keep user logged in between page refreshes

  //         localStorage.setItem('currentUser', JSON.stringify(user));
  //         this.currentUserSubject.next(user);
  //         return user;
  //       })
  //     );
  // }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    //this.currentUserSubject.next(null);
    //this.router.navigate(["/authentication/signin"]);
    return of({ success: false });

    

    // this.subs.sink = this.userService.logout().subscribe((res) => {
    //   if (!res.success) {
    //     this.router.navigate(["/authentication/signin"]);
    //   }
    // });
  }

}
