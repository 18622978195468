import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SurveyServicesService } from 'src/app/api-services/survey-services/survey-services.service';
import { PromoterModel } from 'src/app/Models/affiliate-market';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-affiliate-market',
  templateUrl: './affiliate-market.component.html',
  styleUrls: ['./affiliate-market.component.sass']
})
export class AffiliateMarketComponent implements OnInit {

  endDate: Date = new Date();
  startDate: Date = new Date(new Date().setDate(this.endDate.getDate() - 30));
  totalAmount: number = 0.0;
  exportButtonDisabled: boolean = true;
  
  dataSource: MatTableDataSource<any>;
  promotions: PromoterModel[];
  displayedColumns: string[] = ['applicationName', 'promoName', 'amount'];

  constructor(private appService: SurveyServicesService, private datePipe: DatePipe) { }

  ngOnInit(): void {
    let email: string = window.sessionStorage.getItem("_email");
    this.getPromotionDetails(email);
  }

  getPromotionDetails(email: string, startDate: string = "", endDate: string = "") {
    this.appService.getAffiliateMarketingPromotionDetails(email, startDate, endDate).subscribe(res => {
      if(res && res.data) {
        this.promotions = new Array();
        let telehealthRecords = res.data.promotions.filter(f=>f.applicationId == 1);
        if(telehealthRecords && telehealthRecords.length > 0) {
          const sum = telehealthRecords.reduce((sum, current) => sum + current.promotionAmount, 0);
          let telePromo: PromoterModel = {
            applicationName: telehealthRecords[0].applicationName,
            promoterEmail: email,
            promoName: telehealthRecords[0].promoName, //telehealthRecords[0].promoName,
            startDate: res.data.startDate,
            endDate: res.data.endDate,
            amount: sum
          }
          this.promotions.push(telePromo);
          
        }

        let marketPlaceRecords = res.data.promotions.filter(f=>f.applicationId == 2);
        if(marketPlaceRecords && marketPlaceRecords.length > 0) {
          const sum = marketPlaceRecords.reduce((sum, current) => sum + current.promotionAmount, 0);
          let marketPromo: PromoterModel = {
            applicationName: marketPlaceRecords[0].applicationName,
            promoterEmail: email,
            promoName: marketPlaceRecords[0].promoName, //marketPlaceRecords[0].promoName,
            startDate: res.data.startDate,
            endDate: res.data.endDate,
            amount: sum
          }
          this.promotions.push(marketPromo);
        }
        console.log(this.promotions);
        this.getTotalAmount(this.promotions);
        this.dataSource = new MatTableDataSource<any>(this.promotions);
        this.exportButtonDisabled = false;
      }
    });
  }

  searchRecord() {
    let sDate = this.datePipe.transform(this.startDate, "yyyy-MM-dd");
    let eDate = this.datePipe.transform(this.endDate, "yyyy-MM-dd");
    let email: string = window.sessionStorage.getItem("_email");
    this.getPromotionDetails(email, sDate, eDate);
  }

  getTotalAmount(data) {
    this.totalAmount = data.map(t => t.amount).reduce((acc, value) => acc + value, 0);
  }

  exportexcel() {
    //debugger;
    
    
    let fileName = 'Promotion-Details.xlsx';

    let readyToExport = this.dataSource.data;

    var obj = {};

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(readyToExport);

    //console.log("HEADERS : " + readyToExport);
    var header = Object.keys(readyToExport[0]); // columns name

    var wscols = [];
    for (var i = 0; i < header.length; i++) {  // columns length added
      wscols.push({ wch: header[i].length + 5 })
    }

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName, { bookType: 'xlsx', type: 'buffer' });


  }

}
