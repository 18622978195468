
import { Component, OnInit } from '@angular/core';
import { ServiceSubscription } from 'src/app/Models/survey-details';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SurveyServicesService } from 'src/app/api-services/survey-services/survey-services.service';
import { MarketPlaceService } from 'src/app/api-services/market-place.service';
import { SharedComponentService } from 'src/app/api-services/shared-component-services/shared-component.service';
import { AdminServicesService } from 'src/app/api-services/admin-services/admin-services.service';
import { AlertDialogComponent } from 'src/app/admin/dialog/alert-dialog/alert-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-surveysubscription',
  templateUrl: './surveysubscription.component.html',
  styleUrls: ['./surveysubscription.component.sass'],
})
export class SurveysubscriptionComponent implements OnInit {
  isSpinnerWait: Boolean = true;
  ServiceSubscriptionList: ServiceSubscription[] = [];
  selectedServiceSubscriptions: ServiceSubscription[] = [];
  nonselectedServiceSubscription: ServiceSubscription[] = [];
  selectedServiceSubscriptionIds: number[] = [];
  nonSelectedServiceSubscriptionIds: number[] = [];
  ServiceSubscriptionCharge: number = 0;

  ngOnInit(): void { }

  constructor(
    private surveyService: SurveyServicesService,
    private marketPlaceService: MarketPlaceService,
    private sharedService: SharedComponentService,
    private dialog: MatDialog,
    public adminService: AdminServicesService,
    private _snackBar: MatSnackBar,
    private router: Router,
  ) { 
    this.GetServiceSubscription();
  }

  GetServiceSubscription() {    
    try {
      this.surveyService.getServiceType().subscribe(s => {
        let result = s;
        debugger;
        this.ServiceSubscriptionList = result.data;
        this.ServiceSubscriptionSelect(null, null);
      });
    } catch (e) {
      console.error(e);
    }
  }

  setAmount(services: ServiceSubscription[]) {
    if (services && services.length > 0) {
      if (services.filter(f => f.isChecked).length <= 0) {
        services[0].isChecked = true;
      }
      this.ServiceSubscriptionCharge = 0;
    }
  }

  openAlertDialog(alertMessage: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: alertMessage,
        buttonText: {
          cancel: 'OK',
        },
      },
    });
  }

  ServiceSubscriptionSelect(serviceSubscription: ServiceSubscription | null | undefined, event: any) {
    debugger;
    if (event == null) {
      let nonSelectedIdsOnly = this.ServiceSubscriptionList.map(x => x.serviceTypeId).filter(x => !this.selectedServiceSubscriptions.map(x => x.serviceTypeId).includes(x));
      this.nonselectedServiceSubscription = this.ServiceSubscriptionList.filter(x => nonSelectedIdsOnly.includes(x.serviceTypeId));

      if (this.selectedServiceSubscriptions.length < 1) {
        this.selectedServiceSubscriptionIds = this.selectedServiceSubscriptions.map(x => x.serviceTypeId);
        this.nonSelectedServiceSubscriptionIds.length = 0;
      } else if (this.selectedServiceSubscriptions.length == 12) {
        this.selectedServiceSubscriptionIds = this.selectedServiceSubscriptions.map(x => x.serviceTypeId);
        this.nonSelectedServiceSubscriptionIds = nonSelectedIdsOnly;
      } else if (this.selectedServiceSubscriptions.length > 0) {
        this.selectedServiceSubscriptionIds = this.selectedServiceSubscriptions.map(x => x.serviceTypeId);
        this.nonSelectedServiceSubscriptionIds = nonSelectedIdsOnly;
      }
    } else if (event.checked == true && serviceSubscription != null) {
      if (this.selectedServiceSubscriptions.length < 12) {
        this.selectedServiceSubscriptions.push(serviceSubscription);

        if (this.selectedServiceSubscriptions.length == 1) {
          this.openSnackBar('Products added. Please checkout.', 'Dismiss');
        }
      }

      let nonSelectedIdsOnly = this.ServiceSubscriptionList.map(x => x.serviceTypeId).filter(x => !this.selectedServiceSubscriptions.map(x => x.serviceTypeId).includes(x));
      this.nonselectedServiceSubscription = this.ServiceSubscriptionList.filter(x => nonSelectedIdsOnly.includes(x.serviceTypeId));

      if (this.selectedServiceSubscriptions.length < 12) {
        this.selectedServiceSubscriptionIds = this.selectedServiceSubscriptions.map(x => x.serviceTypeId);
        this.nonSelectedServiceSubscriptionIds.length = 0;
      } else if (this.selectedServiceSubscriptions.length == 12) {
        this.selectedServiceSubscriptionIds = this.selectedServiceSubscriptions.map(x => x.serviceTypeId);
        this.nonSelectedServiceSubscriptionIds = nonSelectedIdsOnly;
      } else if (this.selectedServiceSubscriptions.length > 12) {
        this.selectedServiceSubscriptionIds = this.selectedServiceSubscriptions.map(x => x.serviceTypeId);
        this.nonSelectedServiceSubscriptionIds = nonSelectedIdsOnly;
      }
    } else if (event.checked == false && serviceSubscription != null) {
      if (this.selectedServiceSubscriptions.length >= 1) {
        if (this.selectedServiceSubscriptions.length == 12) {
          this.closeSnackBar();
        }
        let currentProductIndex = this.selectedServiceSubscriptions.map(x => x.serviceTypeId).indexOf(serviceSubscription.serviceTypeId);
        this.selectedServiceSubscriptions.splice(currentProductIndex, 1);
      }

      let nonSelectedIdsOnly = this.ServiceSubscriptionList.map(x => x.serviceTypeId).filter(x => !this.selectedServiceSubscriptions.map(x => x.serviceTypeId).includes(x));
      this.nonselectedServiceSubscription = this.ServiceSubscriptionList.filter(x => nonSelectedIdsOnly.includes(x.serviceTypeId));

      if (this.selectedServiceSubscriptions.length < 12) {
        this.selectedServiceSubscriptionIds = this.selectedServiceSubscriptions.map(x => x.serviceTypeId);
        this.nonSelectedServiceSubscriptionIds.length = 0;
      } else if (this.selectedServiceSubscriptions.length == 12) {
        this.selectedServiceSubscriptionIds = this.selectedServiceSubscriptions.map(x => x.serviceTypeId);
        this.nonSelectedServiceSubscriptionIds = nonSelectedIdsOnly;
      } else if (this.selectedServiceSubscriptions.length > 1) {
        this.selectedServiceSubscriptionIds = this.selectedServiceSubscriptions.map(x => x.serviceTypeId);
        this.nonSelectedServiceSubscriptionIds = nonSelectedIdsOnly;
      }
    }
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 5000,
    });
  }

  closeSnackBar() {
    this._snackBar.dismiss();
  }

  CheckOutServiceSubscription() {
    this.router.navigate(['/intakeform/SubscribeFreeServices']);
  }
  CheckOutSupplement() {
   
    const queryParams = { selectedIds: this.selectedServiceSubscriptionIds.join(',') };
  this.router.navigate(['/intakeform/SubscribeFreeServices'], { queryParams });
  }
}
