<br />
<div class="container-fluid col-lg-12 col-md-12 col-sm-12 text-end" style="width: 90%;">
    <!-- <a href="/productlist?category=All" style="float: right;" class="BuyNowButton btn btn-shop mb-4">
        Return to Products
    </a> -->
    <button class="autoship" (click)="ReturnToSubscription()">Return&nbsp;To&nbsp;Subscription</button>
</div>
<div class="container-fluid col-lg-12 col-md-12 col-sm-12" style="width: 90%;">
    <!-- <div class="mb-3">
        <h2>Customer&nbsp;Details</h2>
    </div> -->
</div>
<form [formGroup]="formGroupProductCheckOutAddress">
    <div class="container-fluid pt-5 pb-5 body_container" style="width: 90%; background-color: white;">

        <div class="row">

           
            <!-- #region SHIPPING DETAILS -->
            <div class="col-12 col-sm-6 col-lg-6">
                <div class="row m-0">

                    <div class="mb-3">
                        <h4>Shipping Details</h4>
                    </div>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>First Name<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="text" placeholder="Type name"
                            formControlName="ShippingDetailsFirstName" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsFirstName', 'required')">First Name is
                            required</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsFirstName', 'maxlength')">First Name cannot be more
                            than 50 characters</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Last Name<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="text" placeholder="Type name"
                            formControlName="ShippingDetailsLastName" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsLastName', 'required')">Last Name is
                            required</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsLastName', 'maxlength')">Last Name cannot be more
                            than 50 characters</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Email <span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="email" placeholder="Type name"
                            formControlName="ShippingDetailsEmail" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsEmail', 'required')">Email is
                            required</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsEmail', 'email')">Email address
                            not proper</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsEmail', 'maxlength')">Email address cannot be more
                            than 100 characters</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Phone <span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="tel" placeholder="Type name"
                            formControlName="ShippingDetailsPhoneNo" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsPhoneNo', 'required')">Phone is
                            required</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsPhoneNo', 'maxlength')">Phone cannot be more
                            than 20 characters</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Shipping Address 1 <span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="500" inputmode="text" placeholder="Type name"
                            formControlName="ShippingDetailsShippingAddress1" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingAddress1', 'required')">Shipping Address 1
                            is required</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingAddress1', 'maxlength')">Shipping Address 1
                            cannot be more than 200 characters</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Shipping Address 2 <span style="color: red;"></span></mat-label>
                        <input matInput maxlength="500" inputmode="text" placeholder="Type name"
                            formControlName="ShippingDetailsShippingAddress2" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingAddress2', 'maxlength')">Shipping Address 2
                            cannot be more than 200 characters</mat-error>
                    </mat-form-field>


                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Shipping City<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="text" placeholder="Type name"
                            formControlName="ShippingDetailsShippingCity" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingCity', 'required')">Shipping City
                            is required</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingCity', 'maxlength')">Shipping City
                            cannot be more than 50 characters</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Shipping State<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="text" placeholder="Type name"
                            formControlName="ShippingDetailsShippingState" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingState', 'required')">Shipping State
                            is required</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingState', 'maxlength')">Shipping State
                            cannot be more than 50 characters</mat-error>
                    </mat-form-field>


                    <!-- <mat-form-field appearance="fill" hintLabel="Country name required"> -->
                    <!-- <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Shipping Country<span style="color: red;"></span></mat-label>
                        <mat-select matNativeControl name="country" formControlName="ShippingDetailsShippingCountry"
                            #choosedValue (valueChange)="GetShippingPriceByCountry(choosedValue.value)" required>
                            <mat-option *ngFor="let country of countryList" [value]="country.Value">
                                {{country.Value}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="checkError('ShippingDetailsShippingCountry', 'required')">Shipping State
                            is required</mat-error>
                    </mat-form-field> -->

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Zip Code<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="10" inputmode="numeric" placeholder="Type name"
                            formControlName="ShippingDetailsZipcode" #nameInput>
                        <mat-error *ngIf="checkError('ShippingDetailsZipcode', 'required')">Zip Code
                            is required</mat-error>
                        <mat-error *ngIf="checkError('ShippingDetailsZipcode', 'maxlength')">Zip Code
                            cannot be more than 10 characters</mat-error>
                    </mat-form-field>

                    <!-- <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>How did you find out about us</mat-label>
                        <input matInput maxlength="300" inputmode="text" placeholder="Type name"
                            formControlName="FindAboutUs" #nameInput>
                         <mat-error *ngIf="checkError('BillingDetailsZipcode', 'required')">Billing Zip Code
                            is required</mat-error> 
                        <mat-error *ngIf="checkError('FindAboutUs', 'maxlength')">Billing Zip Code
                            cannot be more than 300 characters</mat-error>
                    </mat-form-field> -->

                </div>
            </div>
            <!-- #endregion -->

            <!-- #region BILLING DETAILS -->
            <div class="col-12 col-sm-6 col-lg-6">
                <div class="row m-0">

                    <div class="mb-3 row">
                        <h4>Billing Details</h4>
                    </div>

                    <!-- <mat-form-field appearance="fill"> -->
                    <div style=" vertical-align: middle; word-wrap: break-word;" class="pt-3 pb-3">
                        <p>
                            <mat-slide-toggle class="pt-3 " formControlName="BillingDetailIsSameAsShippingAddress"
                                (change)="checkToggle()">
                                Same As Shipping Address</mat-slide-toggle>
                        </p>
                    </div>
                    <!-- </mat-form-field> -->

                    <mat-form-field appearance="fill" class="pt-4">
                        <mat-label>Billing Address <span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="500" inputmode="text" placeholder="Type name"
                            formControlName="BillingDetailsBillingAddress" [readonly]="isBillingDetailsReadonly">
                        <mat-error *ngIf="checkError('BillingDetailsBillingAddress', 'required')">Billing Address
                            is required</mat-error>
                        <mat-error *ngIf="checkError('BillingDetailsBillingAddress', 'maxlength')">Billing Address
                            cannot be more than 400 characters</mat-error>
                    </mat-form-field>


                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Billing City<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="text" placeholder="Type name"
                            formControlName="BillingDetailsCity" [readonly]="isBillingDetailsReadonly">
                        <mat-error *ngIf="checkError('BillingDetailsCity', 'required')">Billing City
                            is required</mat-error>
                        <mat-error *ngIf="checkError('BillingDetailsCity', 'maxlength')">Billing City
                            cannot be more than 50 characters</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Billing State<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="100" inputmode="text" placeholder="Type name"
                            formControlName="BillingDetailsState" [readonly]="isBillingDetailsReadonly">
                        <mat-error *ngIf="checkError('BillingDetailsState', 'required')">Billing State
                            is required</mat-error>
                        <mat-error *ngIf="checkError('BillingDetailsState', 'maxlength')">Billing State
                            cannot be more than 50 characters</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="fill" class="pt-3">
                        <mat-label>Billing Zip Code<span style="color: red;">*</span></mat-label>
                        <input matInput maxlength="10" inputmode="numeric" placeholder="Type name"
                            formControlName="BillingDetailsZipcode" [readonly]="isBillingDetailsReadonly">
                        <mat-error *ngIf="checkError('BillingDetailsZipcode', 'required')">Billing Zip Code
                            is required</mat-error>
                        <mat-error *ngIf="checkError('BillingDetailsZipcode', 'maxlength')">Billing Zip Code
                            cannot be more than 10 characters</mat-error>
                    </mat-form-field>




                </div>


            </div>
            <!-- #endregion -->

        </div>



        <!-- THIS BUTTON SET CAN ALSO BE SET OUTSIDE OF mat-dialog-content -->
        <br /><br />
        <div class="container-fluid modal-footer">

                <!-- <button class="btn-shop-modal-light-inverted-payment" (click)="close()">Close</button>&nbsp; -->
               
                <button class="autoship" type="submit" (click)="MakeSubscription()">&nbsp;&nbsp;Subscribe&nbsp;&nbsp;</button>

        </div>

        <!-- <div class="col-sm-12 text-right"> 
            <button mat-raised-button  color="primary" (click)="confirmClick()"  style="margin-top: 5px;">Pay and Subscribe</button>
        </div> -->


    </div>
    <!-- </form> -->


</form>
<br />
