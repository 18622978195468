<div class="wrapper">
    <mat-card class="container">
        <div class="row">
            <div class="col-sm-12">
                <mat-progress-bar mode="indeterminate" *ngIf="isSpinnerWait"></mat-progress-bar>
            </div>
        </div>
        <form class="form" #form="ngForm" novalidate>
            <h3 class="text-center" style="color: #4043ac;">Welcome to Telehealth Services </h3>
            <h3 class="text-center" style="color: #4043ac; margin-bottom: 25px;">with Dr Stella Immanuel MD </h3>
            <!-- <marquee direction="left" scrollamount="4" onmouseover="this.stop();" onmouseout="this.start();">
                <b>1.</b> ${{procedureRates[0]}} for prevention for Covid-19. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <b>2.</b> ${{procedureRates[1]}} for sick patients! Includes up to 3 follow up visits. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 
                <b>3.</b> ${{procedureRates[2]}} for refill medicines
            </marquee>
            <p class="text-center" style="margin-bottom: 0;">${{procedureRates[0]}} for prevention for Covid-19.</p>
            <p class="text-center" style="margin-bottom: 5px;">${{procedureRates[1]}} for sick patients! Includes up to 3 follow up visits.</p>
            <p class="text-center" style="margin-bottom: 15px;">${{procedureRates[2]}} for refill medicines</p> -->
            <ng-container>
                <div *ngIf="initialDisplay;else other_content">
                    <mat-card-title>Select Survey</mat-card-title>
                </div>
                <ng-template #other_content>
                    <mat-card-title>Enter Details</mat-card-title>
                </ng-template>
            </ng-container>

            <div class="row" *ngIf="initialDisplay">
              <div *ngFor="let type of surveyTypes" class="col-sm-12 text-center survey_type_box">
                <div class="survey_type_inner_box">
                  <br>
                  <div class="row">
                    <div *ngFor="let details of getSurveyDetailsByType(type)" class="col-md-4">
                      <button mat-raised-button color="primary"
                              (click)="surveySelectorClick(details.surveyId)" style="width: 200px;">
                        {{details.surveyName}}
                      </button>
                      <br><br>
                    </div>
                  </div>

                  <!-- <button mat-raised-button color="primary">Already registered patient</button>
    <br><br> -->
                </div>
                <p style="color:blue; text-align:left; margin-left:50px;">In case your concern is not on the list, please choose 'General Health.'</p>
              </div>
            </div>
            <div class="row" *ngIf="!initialDisplay">
                <div class="row">
                    <div class="col-md-12">
                        <h3>Regular medicine refill (Upto 90 Days)</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <ng-multiselect-dropdown name="medicines" [placeholder]="'Select Medicines'"
                            [settings]="dropdownSettings" [data]="dropdownList" [(ngModel)]="selectedItems"
                            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                            (onDeSelect)="onItemDeSelect($event)">
                        </ng-multiselect-dropdown>
                    </div>
                </div>

                <div class="row" style="margin-top: 10px;">
                    <div *ngIf="selectedItems && selectedItems.length <= 0; else selectionContent" class="col-md-12">
                        <mat-card>
                            <mat-card-content>
                                <p>
                                    <mat-icon>add_shopping_cart</mat-icon>
                                    <span style="padding: 10px; vertical-align: super;">
                                        <strong>Empty selection</strong>
                                    </span>
                                </p>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <ng-template #selectionContent>
                        <div *ngFor="let item of regularMedicineList" class="col-md-3">
                            <mat-card>
                                <mat-card-content style="word-wrap: break-word;">
                                    <p>Name: <b>{{item.medicineName}}</b></p>
                                    <p>Category: <b>{{item.categoryName}}</b></p>
                                    <p>Tier: <b>{{item.tier}}</b></p>
                                    <p>Days: <b>{{item.days}}</b></p>
                                    <p>Price: <b>{{item.amount}}</b></p>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </ng-template>
    
                </div>
                <div class="row">
                    <div class="col-md-4" style="margin-top: 10px;">
                        <button mat-raised-button color="primary" (click)="acuteCareRefillSelection()"
                            style="width: 200px;" [disabled]="  selectedItems.length<=0"> Proceed </button>
                    </div>
                </div>
            </div>
            
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <hr>
        </form>
        <mat-card-footer>
            <div class="row">
                <div class="col-md-6">
                    <h5 style="color: red;">
                        <strong class="blink_text">In case of difficulty in filling up the form, please email us
                            at..</strong>
                        <h4>
                            <a style="font-weight: bold; font-size: 18px !important;"
                                href="mailto:patienthelpdesk@drstellamd.com">patienthelpdesk@drstellamd.com</a>
                            <br />
                            <a style="font-weight: bold; font-size: 18px !important;" href="tel:+832-808-5574">Telephone
                                - 832-808-5574</a>
                            <br>
                        </h4>
                    </h5>
                </div>

            </div>
            <div class="row">
                <div class="col-sm-6 align-text-bottom"><img src="assets/img/drstella_logo.png" height="50px"></div>
                <div class="col-sm-6 text-right poweredby align-text-bottom" *ngIf="false">Powered by :<a
                        href="https://www.gracesoft.com" target="_blank"><img src="assets/img/gracesoft_logo.png"
                            height="70px"></a></div>
            </div>
        </mat-card-footer>


    </mat-card>
</div>
