import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogModule, MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SharedComponentService } from "src/app/api-services/shared-component-services/shared-component.service";
import { SurveyServicesService } from "src/app/api-services/survey-services/survey-services.service";
import {
  CompleteSurveyModel,
  patientSearchModel,
  PrintInvoiceModel,
  PromoDetails,
  SurveyMedicine,
  SurveyPaymentDetails,
  SurveySubmissionDetails,
  SurveyPatientDetails,
  SurveyDetails,
  IntakeFormLogModel,
  InvoicePreviewModel,
} from "src/app/Models/survey-details";
import redirectLink from "../../../assets/data/third-party-link.json"
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { PaymentAddressComponent } from "./../payment-address/payment-address.component";
import { TouchSequence } from "selenium-webdriver";
import { AlertDialogComponent } from "src/app/admin/dialog/alert-dialog/alert-dialog.component";
import { AdminServicesService } from "../../api-services/admin-services/admin-services.service";
import { MarketPlaceService } from "src/app/api-services/market-place.service";
import { ConfirmDialogComponent } from "src/app/admin/dialog/confirm-dialog/confirm-dialog.component";
import { PaymentConfirmComponent } from "./payment-confirm/payment-confirm.component";
import { AcuteCareModel } from "src/app/Models/Acute-Care";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.sass"],
})
export class PaymentComponent implements OnInit {
  isSpinnerWait: Boolean = true;
  surveyMedicines: SurveyMedicine[] = [];
  selectedMedicinePackageId: number;
  pharmacyCharge: number = 0;
  procedureCharge: number = 0;
  previewProcedureChargesForSpecialPromo: number = 0;
  isSubscribedOnMarketPlace: boolean = false;
  promo: PromoDetails;
  promoMessage: string = "apply a valid promo code and get discount on amount.";
  surveySubmissionDetails: SurveySubmissionDetails;
  patientSurveyModel: SurveyPatientDetails = {
    patientId: 0,
    firstName: "",
    middleName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    state: "",
    city: "",
    zip: "",
    gender: "",
    dob: "",
    height: "",
    weight: "",
    enrollmentDate: "01/01/9999",
  };

  promoButtonDisabled: boolean = false;
  promoButtonText: string = "Apply";
  procedureText: string;
  beforePaymentSection: boolean = true;
  generatedPaymentId: number;
  invoiceData: PrintInvoiceModel[];
  generationInvoiceHtml: string;
  isSubmitInProgress: boolean = false;
  isDownloadInProgress: boolean = false;
  patientId: number;
  pachageCheckboxName: string = "packageName";
  surveyDetails: SurveyDetails;
  initialLoad: boolean = true;
  isAddressFound: boolean = false;
  ipAddress: string = "";
  browserName: string = "";
  browserVersion: string = "";
  clientMetaData: string = "";

  formDataCaptureString: string = "";

  @ViewChild("pdfTable", { static: false }) pdfTable: ElementRef;

  paymentForm = new FormGroup({
    CardHolderName: new FormControl("", [Validators.required]),
    CardNumber: new FormControl("", [
      Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.maxLength(16),
    ]),
    ExpiryMonth: new FormControl("", [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(2),
      Validators.pattern("^[0-9]*$"),
    ]),
    ExpiryYear: new FormControl("", [
      Validators.required,
      Validators.minLength(4),
      Validators.maxLength(4),
      Validators.pattern("^[0-9]*$"),
    ]),
    cvc: new FormControl("", [
      Validators.required,
      Validators.maxLength(4),
      Validators.pattern("^[0-9]*$"),
    ]),
    Amount: new FormControl(""),
    PromoCode: new FormControl(""),
    FindAboutUs: new FormControl("")
  });

  constructor(
    private surveyService: SurveyServicesService,
    private marketPlaceService: MarketPlaceService,
    private sharedService: SharedComponentService,
    private surveyServices: SurveyServicesService,
    private dialog: MatDialog,
    public adminService: AdminServicesService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.surveyDetails = JSON.parse(sessionStorage.getItem("selectedSurvey"));
    if (this.surveyDetails.surveyTypeCode != "5" && this.sharedService.currentSurveySubmissionDetails === undefined) {
      this.openAlertDialog("Please fill the servey form first.");
      this.router.navigate(["/intakeform/selector"]);
    } else {
      this.initialLoader();
    }
  }

  initialLoader() {
    debugger;
    let storeurveySubmissionDetails = JSON.parse(sessionStorage.getItem("_criticalDataForPaymentPage"));
    if (this.surveyDetails.surveyTypeCode == "5") {
      let acuteCareModel: AcuteCareModel = JSON.parse(sessionStorage.getItem("_acuteCare"));
      this.surveySubmissionDetails = acuteCareModel.surveySubmissionDetails;
    }
    else {
      this.sharedService.currentSurveySubmissionDetails.subscribe((data) => {
        if (data) {
          this.surveySubmissionDetails = data;
        }
        else if (storeurveySubmissionDetails) {
          this.surveySubmissionDetails = storeurveySubmissionDetails;
          //implementation of refresh payment page.
        }
      });
    }

    if (this.surveySubmissionDetails) {
      this.procedureCharge = this.surveySubmissionDetails.surveyDetails.procedureRate;
      if (this.surveyDetails.surveyTypeCode == "5") {
        this.procedureText = this.surveySubmissionDetails.surveyDetails.surveyName;
      }
      else {
        this.procedureText = this.surveySubmissionDetails.surveyDetails.surveyName + " - " + this.surveySubmissionDetails.surveyDetails.surveyTypeName;
      }

      this.patientId = this.surveySubmissionDetails.patientDetails.patientId;

      this.reloadAddress();
      if (this.surveyDetails.surveyTypeCode != "5") {
        this.surveyService.getMedicinesBySurveyDetailsId(this.surveySubmissionDetails.surveyDetails.surveyId).subscribe((res) => {
          if (res.data) {
            this.isSpinnerWait = false;
            console.log(res.data);
            //for not acute care
            this.surveyMedicines = res.data;

          }
          if (this.surveySubmissionDetails?.patientDetails?.email) {
            this.marketPlaceService.validateAutoshipOfferSubscription(this.surveySubmissionDetails.patientDetails.email).subscribe((res) => {
              if (res && res.Data == true) {
                this.isSubscribedOnMarketPlace = res.Data;
                this.procedureCharge = 0;
                if (this.surveyDetails.surveyTypeCode == "5") {
                  this.procedureText = this.surveySubmissionDetails.surveyDetails.surveyName;
                }
                else {
                  this.procedureText = this.surveySubmissionDetails.surveyDetails.surveyName + " - " +
                    this.surveySubmissionDetails.surveyDetails.surveyTypeName + " - Subscription in Market Place";
                }

              }
              this.isSpinnerWait = false;
              this.setAmount(this.surveyMedicines);
            });
          }
        });
      }
      else {
        this.isSpinnerWait = false;
        if (this.surveyDetails.surveyId == 12) {
          let medicines: SurveyMedicine[] = JSON.parse(sessionStorage.getItem("_acuteCareRegularRefillMedicines"));
          this.surveyMedicines = medicines;
          this.setAmount(this.surveyMedicines);
        }
        else {
          this.setAmount(null);
        }


      }

    }
  }

  reloadAddress() {
    this.surveyServices
      .GetOnlyPatientDetails(this.patientId).subscribe((patientData) => {
        this.isAddressFound = true;
        if (patientData.data != null) {
          this.patientSurveyModel.patientId = patientData.data.patientId;
          this.patientSurveyModel.firstName = patientData.data.firstName;
          this.patientSurveyModel.lastName = patientData.data.lastName;
          this.patientSurveyModel.phone = patientData.data.phone;
          this.patientSurveyModel.email = patientData.data.email;
          this.patientSurveyModel.address = patientData.data.address;
          this.patientSurveyModel.state = patientData.data.state;
          this.patientSurveyModel.city = patientData.data.city;
          this.patientSurveyModel.zip = patientData.data.zip;
          this.patientSurveyModel.gender = patientData.data.gender;
          this.patientSurveyModel.dob = patientData.data.dob;
          this.patientSurveyModel.height = patientData.data.height;
          this.patientSurveyModel.weight = patientData.data.weight;
          this.patientSurveyModel.enrollmentDate = patientData.data.enrollmentDate;
        } else {
          this.isAddressFound = false;
        }
      });
  }

  selectMedicine(event, medicinePackageId: number) {
    if (event.checked && (medicinePackageId == 66 || medicinePackageId == 67)) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        disableClose: true,
        data: {
          message: "Thank you for selecting CoviCare complete. It is not for sick patients but to stock up in eventuality. It’s a complete package to treat yourself at home. If you need to speak to a doctor in the future you’ll have to sign up as general care, return patient.Since it’s not sick care, your meds will not be shipped urgently.",
          buttonText: {
            ok: 'OK',
            cancel: 'Go back and register as a sick patient'
          }
        }
      });

      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          const a = document.createElement('a');
          a.click();
          a.remove();
          this.selectMedicineOperation(event, medicinePackageId);
        }
        else {
          this.router.navigate(['/intakeform/selector']);
        }
      });

    }
    else {
      this.selectMedicineOperation(event, medicinePackageId);
    }

  }

  selectMedicineOperation(event, medicinePackageId: number) {
    this.selectedMedicinePackageId = medicinePackageId;
    if (this.selectedMedicinePackageId > 0) {
      if (this.surveyDetails.surveyId != 1 && this.surveyDetails.surveyId != 2) {
        this.surveyMedicines.forEach((f) => {
          if (f.medicinePackageId == medicinePackageId) {
            f.isChecked = event.checked;
          } else {
            f.isChecked = false;
          }
        });
      } else {
        this.surveyMedicines.forEach((f) => {
          if (f.medicinePackageId == medicinePackageId) {
            f.isChecked = event.checked;
          }
        });
      }

      let result: number = 0;
      if (this.surveyMedicines.filter((f) => f.isChecked).length > 0) {
        result = this.surveyMedicines
          .filter((f) => f.isChecked)
          .map((a) => a.amount)
          .reduce(function (a, b) {
            return a + b;
          });

        //consider promo code
        this.promoButtonDisabled = false;
        this.promoMessage =
          "apply a valid promo code and get discount on amount.";
      } else {
        result = 0;
        this.promoButtonDisabled = true;
      }

      this.pharmacyCharge = result;
      this.procedureCharge = (this.promo?.promocodeId == 2022) ? this.previewProcedureChargesForSpecialPromo : this.procedureCharge;
      this.paymentForm.patchValue({
        Amount: Number((this.pharmacyCharge + this.procedureCharge).toFixed(2)).toString(),
      });
    } else {
      this.setAmount(this.surveyMedicines);
    }
  }

  eitherOrVisibility() {
    if (this.surveyDetails.surveyId == 1 || this.surveyDetails.surveyId == 2) {
      return false;
    } else {
      return true;
    }
  }

  getTotalQuantity(medicines: SurveyMedicine[]) {
    let sum: number = medicines
      .map((a) => a.quantity)
      .reduce(function (a, b) {
        return a + b;
      });
    return sum;
  }

  getTotalAmount(medicines: SurveyMedicine[]) {
    let sum: number = medicines
      .map((a) => a.amount)
      .reduce(function (a, b) {
        return a + b;
      });
    return sum;
  }

  setAmount(medicines: SurveyMedicine[]) {

    if (medicines && medicines.length > 0) {
      if (medicines.filter((f) => f.isChecked).length <= 0) {
        medicines[0].isChecked = true;
      }
      let sum: number = medicines
        .filter((f) => f.isChecked)
        .map((a) => a.amount)
        .reduce(function (a, b) {
          return a + b;
        });
      this.pharmacyCharge = sum;
      this.paymentForm.patchValue({
        Amount: Number((this.pharmacyCharge + this.procedureCharge).toFixed(2)).toString(),
      });
    } else {
      this.paymentForm.patchValue({
        Amount: Number(this.procedureCharge.toFixed(2)).toString(),
      });
    }
  }

  getPromoDetails() {
    debugger;
    let promoName = this.paymentForm.value["PromoCode"];
    let deductedAmount = 0;
    let totalAmount = Number(
      (this.pharmacyCharge + this.procedureCharge).toFixed(2)
    );
    this.surveyService
      .getPromoDetailsByPromoName(promoName)
      .subscribe((res) => {
        if (res.data) {
          this.promo = res.data;
          if (this.promo != null) {
            if (this.promo.promocodeId == 2022) {
              deductedAmount = this.procedureCharge;
              this.promoMessage =
                "$" + this.procedureCharge + " deducted from amount !";
              totalAmount = Number((totalAmount - deductedAmount).toFixed(2));
              this.paymentForm.patchValue({
                Amount: totalAmount.toString(),
              });
              this.previewProcedureChargesForSpecialPromo = this.procedureCharge;
              this.procedureCharge = 0;
            } else {
              deductedAmount = Number(
                ((totalAmount * this.promo.promocodePercent) / 100).toFixed(2)
              );
              this.promoMessage =
                "$" + deductedAmount + " deducted from amount !";
              totalAmount = Number((totalAmount - deductedAmount).toFixed(2));
              this.paymentForm.patchValue({
                Amount: totalAmount.toString()
              });
            }
            this.promoButtonDisabled = true;
            this.promoButtonText = "Applied";
          } else {
            this.promoMessage = "Invalid promo code";
          }
        }
      });
  }

  submitPaymentForm() {
    debugger;
    if (this.paymentForm.valid) {
      var isFromAcuteCare = sessionStorage.getItem("_isAcuteSurvey");
      if (this.surveySubmissionDetails.surveyDetails.surveyId == 10 || this.surveySubmissionDetails.surveyDetails.surveyId == 11 || isFromAcuteCare == "yes") {
        let invoicePreviewData: InvoicePreviewModel[] = [];
        //if(this.surveySubmissionDetails.surveyDetails.surveyId == 12) {
        if (this.surveyMedicines && this.surveyMedicines.length > 0) {
          this.surveyMedicines.filter((f) => f.isChecked).forEach((f) => {
            invoicePreviewData.push({
              name: f.medicineName,
              amount: f.amount,
              quantity: 1,
              isPromo: false
            });
          });
        }

        //}
        //else {

        invoicePreviewData.push({
          name: "Procedure Charge",
          amount: this.procedureCharge,
          quantity: 1,
          isPromo: false,
        });
        //}


        if (this.promo != null) {
          let packageAmounts = 0;
          if (this.surveyMedicines && this.surveyMedicines.length > 0) {
            packageAmounts = this.surveyMedicines.filter((f) => f.isChecked).map((a) => a.amount).reduce(function (a, b) {
              return a + b;
            });
          }
          let deductionAmount = Number(((this.procedureCharge + packageAmounts) * this.promo.promocodePercent) / 100).toFixed(2);
          invoicePreviewData.push({
            name: "Applied promo - " + this.promo.promocodeName + "(" + this.promo.promocodePercent + " %)",
            amount: Number(-deductionAmount),
            quantity: 1,
            isPromo: true,
          });
        }

        if (this.surveySubmissionDetails.surveyDetails.shippingCharge > 0) {
          invoicePreviewData.push({
            name: "Shipping charge",
            amount: this.surveySubmissionDetails.surveyDetails.shippingCharge,
            quantity: 1,
            isPromo: false,
          });
        }

        const confirmRef = this.dialog.open(PaymentConfirmComponent, { data: invoicePreviewData });
        confirmRef.afterClosed().subscribe((data) => {
          if (data == true) {
            this.makePaymentProcess();
          }
        });
      } else {
        if (this.surveyMedicines.filter((f) => f.isChecked).length > 0) {
          let invoicePreviewData: InvoicePreviewModel[] = [];
          invoicePreviewData.push({
            name: "Procedure Charge",
            amount: (this.promo?.promocodeId == 2022) ? this.previewProcedureChargesForSpecialPromo : this.procedureCharge,
            quantity: 1,
            isPromo: false,
          });

          this.surveyMedicines.filter((f) => f.isChecked).forEach((f) => {
            invoicePreviewData.push({
              name: f.medicineName,
              amount: f.amount,
              quantity: 1,
              isPromo: false,
            });
          });

          if (this.promo != null) {
            if (this.promo.promocodeId == 2022) {
              let packageAmounts = this.surveyMedicines.filter((f) => f.isChecked).map((a) => a.amount).reduce(function (a, b) {
                return a + b;
              });
              let deductionAmount = this.previewProcedureChargesForSpecialPromo;
              invoicePreviewData.push({
                name: "Applied promo - " + this.promo.promocodeName + "100% deduction on procedure charges",
                amount: Number(-deductionAmount),
                quantity: 1,
                isPromo: true,
              });
            }
            else {
              let packageAmounts = this.surveyMedicines.filter((f) => f.isChecked).map((a) => a.amount).reduce(function (a, b) {
                return a + b;
              });
              let deductionAmount = Number(((this.procedureCharge + packageAmounts) * this.promo.promocodePercent) / 100).toFixed(2);
              invoicePreviewData.push({
                name: "Applied promo - " + this.promo.promocodeName + "(" + this.promo.promocodePercent + " %)",
                amount: Number(-deductionAmount),
                quantity: 1,
                isPromo: true,
              });
            }
          }

          if (this.surveySubmissionDetails.surveyDetails.shippingCharge > 0) {
            invoicePreviewData.push({
              name: "Shipping charge",
              amount: this.surveySubmissionDetails.surveyDetails.shippingCharge,
              quantity: 1,
              isPromo: false,
            });
          }

          const confirmRef = this.dialog.open(PaymentConfirmComponent, {
            data: invoicePreviewData,
          });
          confirmRef.afterClosed().subscribe((data) => {
            if (data == true) {
              this.makePaymentProcess();
            }
          });
        } else {
          this.openAlertDialog(
            "Please select any one package from listed packages."
          );
        }
      }
    } else {
      window.scroll({ top: window.innerHeight / 2.6 });
      this.openAlertDialog("please fill up required field.");
    }
  }

  makePaymentProcess() {
    debugger;
    let surveyDetails: SurveyDetails = JSON.parse(sessionStorage.getItem("selectedSurvey"));

    this.isSubmitInProgress = true;

    let form = this.paymentForm;
    let pharmacyCharge = 0;
    let procedureCharge = 0;
    if (surveyDetails.surveyTypeCode == '5') {
      procedureCharge = this.surveySubmissionDetails.surveyDetails.procedureRate;
    }
    else {
      procedureCharge = this.isSubscribedOnMarketPlace ? 0 : this.surveySubmissionDetails.surveyDetails.procedureRate;
    }

    //consider all package option
    if (this.surveyMedicines.filter((f) => f.isChecked).length > 0) {
      pharmacyCharge = this.surveyMedicines.filter((f) => f.isChecked).map((a) => a.amount).reduce(function (a, b) {
        return a + b;
      });
    } else {
      pharmacyCharge = 0;
    }
    //consider promo
    let promoId: number | null = null;
    let amountAfterDeduction: number = 0;
    if (this.promo != null) {
      promoId = this.promo.promocodeId;
      amountAfterDeduction = Number(form.value["Amount"]);
    }

    //consider shipping charge
    let shippingCharge: number = this.surveySubmissionDetails.surveyDetails.shippingCharge;

    //consider promo code on total amount calculation

    let totalAmount: number = Number(
      (procedureCharge + pharmacyCharge + shippingCharge).toFixed(2)
    );

    let paymentDetails: SurveyPaymentDetails = {
      cardHolderName: form.value["CardHolderName"],
      cardNumber: form.value["CardNumber"],
      ExpiryMonth: form.value["ExpiryMonth"],
      ExpiryYear: form.value["ExpiryYear"],
      cvc: form.value["cvc"],
      promoId: promoId,
      procedureCharge: procedureCharge,
      pharmacyCharge: pharmacyCharge,
      shippingCharge: shippingCharge,
      amount: totalAmount,
      amountAfterDeduction: amountAfterDeduction,
      medicinePackageIds: this.surveyMedicines
        .filter((f) => f.isChecked)
        .map((m) => m.medicinePackageId),
      isSubscribedOnMarketPlace: this.isSubscribedOnMarketPlace,
      findAboutUs: form.value["FindAboutUs"]
    };

    let submitModel: CompleteSurveyModel = {
      surveyPaymentDetails: paymentDetails,
      surveySubmissionDetails: this.surveySubmissionDetails,
    };
    console.log(submitModel);

    if (submitModel.surveySubmissionDetails.patientDetails != null && !submitModel.surveySubmissionDetails.patientDetails.firstName) {
      this.openAlertDialog("patient info missing.");
      console.log(submitModel.surveySubmissionDetails.patientDetails);
    }
    debugger;
    if (this.patientSurveyModel.patientId == submitModel.surveySubmissionDetails.patientDetails.patientId
      && surveyDetails.surveyId == submitModel.surveySubmissionDetails.surveyDetails.surveyId && surveyDetails.RefPatientStatus == 7) {
      this.openAlertDialog(
        "This survey is already exist for this user. Please choose another survey."
      );
      this.isSubmitInProgress = false;
      console.log(submitModel.surveySubmissionDetails.surveyDetails);
      //this.router.navigate(["/intakeform/selector"]);
    }

    this.formDataCaptureString = "";

    var tempCardJson = '{  "PaymentForm" : {  "Amount" : "' + this.paymentForm.controls.Amount.value + '" ,'
      + '"CardHolderName" : "' + this.paymentForm.controls.CardHolderName.value + '" ,'
      + '"CardNumber" : "' + this.paymentForm.controls.CardNumber.value + '" ,'
      + '"ExpiryMonth" : "' + this.paymentForm.controls.ExpiryMonth.value + '" ,'
      + '"ExpiryYear" : "' + this.paymentForm.controls.ExpiryYear.value + '" ,'
      + '"PromoCode" : "' + this.paymentForm.controls.PromoCode.value + '" },';

    tempCardJson = tempCardJson + ' "SurveyDetails" : ' + JSON.stringify(this.surveyDetails) + ',';
    tempCardJson = tempCardJson + ' "SurveyMedicinesList" : ' + JSON.stringify(this.surveyMedicines) + '} ';

    this.formDataCaptureString = this.formDataCaptureString + tempCardJson;

    if (surveyDetails.surveyTypeCode == '5') {
      debugger;
      let acuteCareModel: AcuteCareModel = JSON.parse(sessionStorage.getItem("_acuteCare"));
      acuteCareModel.surveyPaymentDetails = paymentDetails;
      acuteCareModel.answer = sessionStorage.getItem("_acuteAnswer");
      acuteCareModel.answerDesc = sessionStorage.getItem("_acuteAnswerDesc");
      acuteCareModel.acuteCareStepsDetails = sessionStorage.getItem("_acuteCareStepsDetails");
      acuteCareModel.acuteCareFileUpload = JSON.parse(sessionStorage.getItem("_acuteCareSavedFiles"));
      if (surveyDetails.surveyId == 12) {
        acuteCareModel.acuteCaremedicineUpload = JSON.parse(sessionStorage.getItem("_acuteCareMedicineFiles"));
        let selectedMedicines: SurveyMedicine[] = JSON.parse(sessionStorage.getItem("_acuteCareRegularRefillMedicines"));
        acuteCareModel.selectedRegularReMedicineList = this.getDrugListFromSelectedMedicines(selectedMedicines);
        console.log(acuteCareModel);
        this.completeAcuteCareRegularRefill(acuteCareModel);
      }
      else {
        console.log(acuteCareModel);
        this.completeAcuteCareGeneralTreatment(acuteCareModel);
      }

    }
    else {
      this.surveyService.completeSurveyProcess(submitModel).subscribe((res) => {
        if (res.data != null && res.data > 0) {
          //settle autoship offer subscription
          if (this.isSubscribedOnMarketPlace) {
            this.settleAutoshipOffer(submitModel.surveySubmissionDetails.patientDetails.email);
          }
          this.generatedPaymentId = res.data;
          console.log(res.data);
          //Entry to log
          var logGuid = localStorage.getItem("logGuid");
          if (logGuid == null || logGuid == "") {
            logGuid = "";
          } else {
            localStorage.removeItem("logGuid");
          }

          var logModel: IntakeFormLogModel = {
            logConnId: logGuid, //it should be blank
            userPhone: this.surveySubmissionDetails.patientDetails.phoneNo,
            userEmail: this.surveySubmissionDetails.patientDetails.email,
            userAction:
              this.surveySubmissionDetails.surveyDetails.surveyTypeName +
              "->" +
              this.surveySubmissionDetails.surveyDetails.surveyName +
              "-> User found + Payment Page Process Successful with Amount : " +
              paymentDetails.amount +
              "-> With PromoId : " +
              paymentDetails.promoId +
              "-> With Card No : " +
              paymentDetails.cardNumber,
            userActionCode: paymentDetails.promoId == null ? 210 : 211,
            ipAddress: localStorage.getItem("clientIp"),
            browserName: this.adminService.detectBrowserName(),
            browserVersion: this.adminService.detectBrowserVersion(),
            clientMetaData: this.adminService.detectClientDeviceMetadata(),
            formData: "Payment: " + this.formDataCaptureString,
          };
          this.insertIntakeFormLog(logModel);
          sessionStorage.removeItem("selectedSurvey");
          sessionStorage.removeItem("procedureRate");
          sessionStorage.removeItem("existingSubmissionId");
          sessionStorage.removeItem("existingPatientId");
          localStorage.removeItem("intakeFormEmail");
          sessionStorage.removeItem("patientAns");
          sessionStorage.removeItem("_isAcuteSurvey");


          sessionStorage.removeItem("_acuteCare");
          sessionStorage.removeItem("_acuteAnswer");
          sessionStorage.removeItem("_acuteAnswerDesc");
          sessionStorage.removeItem("_acuteCareStepsDetails");
          sessionStorage.removeItem("_criticalDataForPaymentPage");
          sessionStorage.removeItem("_AcuteCarePatientState");

          this.router.navigateByUrl("/intakeform/payment-invoice?paymentId=" + this.generatedPaymentId);
        } else if (res.error != null) {
          this.isSubmitInProgress = false;
          this.openAlertDialog(res.error.errorMessage);

          //Entry to log
          var logGuid = localStorage.getItem("logGuid");
          if (logGuid == null || logGuid == "") {
            logGuid = "";
          } else {
            localStorage.removeItem("logGuid");
          }
          var logModel: IntakeFormLogModel = {
            logConnId: logGuid, //it should be blank
            userPhone: this.surveySubmissionDetails.patientDetails.phoneNo,
            userEmail: this.surveySubmissionDetails.patientDetails.email,
            userAction:
              this.surveySubmissionDetails.surveyDetails.surveyTypeName + "->" + "-> User found + Payment Page Process Issue: " + res.error.errorMessage,
            userActionCode: 221,
            ipAddress: localStorage.getItem("clientIp"),
            browserName: this.adminService.detectBrowserName(),
            browserVersion: this.adminService.detectBrowserVersion(),
            clientMetaData: this.adminService.detectClientDeviceMetadata(),
            formData: "Payment: " + this.formDataCaptureString,
          };
          this.insertIntakeFormLog(logModel);
          localStorage.removeItem("intakeFormEmail");
        } else {
          this.isSubmitInProgress = false;
          this.openAlertDialog(res.error.errorMessage);

          //Entry to log
          debugger;
          var logGuid = localStorage.getItem("logGuid");
          if (logGuid == null || logGuid == "") {
            logGuid = "";
          } else {
            localStorage.removeItem("logGuid");
          }
          var logModel: IntakeFormLogModel = {
            logConnId: logGuid, //it should be blank
            userPhone: this.surveySubmissionDetails.patientDetails.phoneNo,
            userEmail: this.surveySubmissionDetails.patientDetails.email,
            userAction:
              this.surveySubmissionDetails.surveyDetails.surveyTypeName + "->" + "-> User found + Payment Page Process Issue: " + res.error.errorMessage,
            userActionCode: 222,
            ipAddress: localStorage.getItem("clientIp"),
            browserName: this.adminService.detectBrowserName(),
            browserVersion: this.adminService.detectBrowserVersion(),
            clientMetaData: this.adminService.detectClientDeviceMetadata(),
            formData: "Payment: " + this.formDataCaptureString,
          };
          this.insertIntakeFormLog(logModel);
          localStorage.removeItem("intakeFormEmail");
        }
      });
    }

  }

  completeAcuteCareGeneralTreatment(acuteCareModel: AcuteCareModel) {
    this.surveyService.completeAcuteCareProcess(acuteCareModel).subscribe(res => {
      if (res.data) {
        console.log(res);

        //console.log(redirectLink.onlineConsultLink + "book-appointment/" + res.data.user_guid + "/order/" + res.data.data);
        if (acuteCareModel.surveySubmissionDetails.surveyDetails.surveyId == 24) {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
              message: 'Thank you for choosing our service. Click "Yes" to go the online consult page.',
              buttonText: {
                ok: 'Yes',
                cancel: ''
              }
            },
            disableClose: true
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              const a = document.createElement('a');
              a.click();
              a.remove();
              window.location.href = res.data;
            }
          });
        }
        else {
          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
              message: ' Thank you for choosing our service. You will receive the appointment details via email.',
              buttonText: {
                ok: 'Yes',
                cancel: ''
              }
            },
            disableClose: true
          });

          dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
              const a = document.createElement('a');
              a.click();
              a.remove();
              //window.location.href = redirectLink.onlineConsultLink + "book-appointment/" + res.data.user_guid + "/order/" + res.data.data;
              window.location.href = res.data;
            }
          });
        }

      }
      else {
        if (res.error) {
          this.openAlertDialog(res.error.errorMessage);
          this.isSubmitInProgress = false;
        }
        else {
          this.openAlertDialog("Error occurred.");
          this.isSubmitInProgress = false;
        }

      }
    });
  }

  completeAcuteCareRegularRefill(acuteCareModel: AcuteCareModel) {
    debugger;
    this.surveyService.completeAcuteCareRefill(acuteCareModel).subscribe(res => {
      if (res.data) {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            message: ' Thank you for choosing our service. You will receive the appointment details via email.',
            buttonText: {
              ok: 'Yes',
              cancel: ''
            }
          },
          disableClose: true
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            const a = document.createElement('a');
            a.click();
            a.remove();
            window.location.href = "https://marketplace.drstellamd.com/";
          }
        });
      }
      else {
        if (res.error) {
          this.openAlertDialog(res.error.errorMessage);
          this.isSubmitInProgress = false;
        }
        else {
          this.openAlertDialog("Error occurred.");
          this.isSubmitInProgress = false;
        }

      }
    });
  }

  getDrugListFromSelectedMedicines(list: SurveyMedicine[]) {
    let drugList: any[] = [];
    if (list && list.length > 0) {
      list.forEach(f => {
        let drug = {
          category_id: f.categoryId,
          drug_id: f.medicineId,
          drug_full_name: f.medicineName,
          tier: f.tier,
          price: f.amount.toString(),
          base_price: f.acuteCare_refillPrice.toString(),
          days: f.days
        };
        drugList.push(drug);
      });

    }
    return drugList;
  }



  openAlertDialog(alertMessage: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: alertMessage,
        buttonText: {
          cancel: "OK",
        },
      },
    });
  }

  public checkError = (controlName: string, errorName: string) => {
    return this.paymentForm.controls[controlName].hasError(errorName);
  };

  disallowSpace(event) {
    return event.keyCode != 32 ? event : event.preventDefault();
  }

  openEditAddressForm(element: any) {
    let data = { selectedRowData: this.patientSurveyModel };
    const dialogRef = this.dialog.open(PaymentAddressComponent, { data: data });
    dialogRef.afterClosed().subscribe((data) => {
      this.reloadAddress();
    });
  }

  insertIntakeFormLog(logModel: IntakeFormLogModel) {

    this.adminService.InsertIntakeFormLog(logModel).subscribe((data) => {
      if (data.error == null) {
        localStorage.setItem("logGuid", JSON.stringify(data.data));
      } else {
        //error in log
      }
    });
  }

  settleAutoshipOffer(email: string) {
    debugger;
    let model = { emailAddress: email };
    this.marketPlaceService.settleAutoshipOffer(model).subscribe((res) => {
      if (res && res.Data) {
        console.log(res.Data);
      }
    });
  }
}
