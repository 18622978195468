import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ServiceUrl } from './service-url';

@Injectable({
  providedIn: 'root'
})
export class MarketPlaceService {

  marketPlaceServices: string;
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private serviceUrl: ServiceUrl, private http: HttpClient) {
    this.marketPlaceServices = this.serviceUrl.marketPlace;
  }

  errorHandler(error: HttpErrorResponse) {
    let errorMessage = `Error code ${error.status}\nMessage: ${error.message}`;
    return throwError(errorMessage);
  }

  validateAutoshipOfferSubscription(patientEmail: string) {
    return this.http.get<any>(`${this.marketPlaceServices}ValidateAutoshipOfferSubscription?patientEmail=` + patientEmail,
      { headers: this.headers }).pipe(catchError(this.errorHandler));
  }

  settleAutoshipOffer(model: any): Observable<any> {
    var url = `${this.marketPlaceServices}SettleAutoshipOffer`;
    var postData = JSON.stringify(model);
    return this.http.post(url, postData, { headers: this.headers }).pipe(
      catchError(this.errorHandler));
  }
  
}
