import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { LocationStrategy } from '@angular/common';
import { SurveyServicesService } from 'src/app/api-services/survey-services/survey-services.service';
import { SharedComponentService } from 'src/app/api-services/shared-component-services/shared-component.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.sass']
})
export class InvoiceComponent implements OnInit {

  paymentId: number;
  beforePaymentSection: boolean = true;
  isDownloadInProgress: boolean = false;

  constructor(private route: ActivatedRoute, private surveyService: SurveyServicesService,
    private sharedService: SharedComponentService,
    private router: Router, private location: LocationStrategy) {
    this.sharedService.currentSurveySubmissionDetails = undefined;
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(s => {
      if (s.paymentId && s.paymentId > 0) {
        this.beforePaymentSection = true;
        this.paymentId = s.paymentId;
      }
      else {
        this.beforePaymentSection = false;
      }
    });
  }

  downloadInvoice() {
    this.isDownloadInProgress = true;
    this.surveyService.getGeneratedInvoice2(this.paymentId).subscribe(d => {
      if (d) {
        if (d != null) {
          const blob = new Blob([d], { type: 'application/pdf' })
          const fileName = 'Invoice.pdf';
          saveAs(blob, fileName);
          this.isDownloadInProgress = false;
          this.router.navigate(["/intakeform/selector"]);
        }
        else {
          alert("unable to download the invoice at this time.");
        }
      }
      else {
        alert("unable to download the invoice at this time.");
      }

    });
  }

}
