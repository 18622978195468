import { DatePipe, LocationStrategy } from '@angular/common';
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertDialogComponent } from 'src/app/admin/dialog/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from 'src/app/admin/dialog/confirm-dialog/confirm-dialog.component';
import { AdminServicesService } from 'src/app/api-services/admin-services/admin-services.service';
import { SharedComponentService } from 'src/app/api-services/shared-component-services/shared-component.service';
import { SurveyServicesService } from 'src/app/api-services/survey-services/survey-services.service';
import { AcuteCareModel } from 'src/app/Models/Acute-Care';
import { ChildDisplay, IntakeFormLogModel, SurveyDetails, SurveyOption, SurveyQuestion, SurveyStep, SurveySubmissionDetails } from 'src/app/Models/survey-details';
import { RegularSampleMedicineComponent } from './regular-sample-medicine/regular-sample-medicine.component';

@Component({
  selector: 'app-acute-care-steps',
  templateUrl: './acute-care-steps.component.html',
  styleUrls: ['./acute-care-steps.component.scss']
})
export class AcuteCareStepsComponent implements OnInit {

  @ViewChild('scrollPanel') public panel: ElementRef;

  surveySteps: SurveyStep[];
  currentStep: SurveyStep = {
    questions: [],
    stepCode: 0,
    stepID: 0,
    subTitle: "",
    tabOrder: 0,
    title: ""
  };


  patientGender = [{
    optionValue: "Male",
    itemsInRow: 2
  }, {
    optionValue: "Female",
    itemsInRow: 2
  }]

  isSpinnerWait: boolean = false;

  acuteCareModel: AcuteCareModel;
  telehealthSurveyId: number = 11;
  medicineFiles: any[] = [];
  savedFilesName: string = "";
  @ViewChild("fileInput") medicineFileInput: ElementRef;

  stepCounter: number = 0;
  maxStepIndex: number;
  @ViewChild('form') form: any;
  @ViewChild('myElement') desc: any;
  @ViewChildren("textInputs") textInputs: QueryList<ElementRef>;
  formCompleted: boolean = false;
  surveySubmissionDetails: SurveySubmissionDetails;
  selectedSurveyId: number;
  countForEmail: number = 1;
  emailValue: string;
  phoneValue: string;


  isDisplayPreviousButton: boolean = false;
  isDisplayNextButton: boolean = true;
  answers: { [id: number]: string; } = {};
  descAnswer: { [desc: string]: string } = {}

  isNextButtonDisabled: boolean = false;
  selectedStateValue: string = "";
  formDataCaptureString: string = "";

  childDisplayArry: ChildDisplay[];

  acuteCarePharmacyList: any[];

  selectedSurvey: SurveyDetails = JSON.parse(sessionStorage.getItem("selectedSurvey"));


  constructor(private surveyServices: SurveyServicesService, public adminService: AdminServicesService,
    private sharedService: SharedComponentService, private router: Router, private dialog: MatDialog,
    private el: ElementRef, private location: LocationStrategy) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  ngOnInit(): void {
    let storeState = sessionStorage.getItem("_AcuteCarePatientState");
    this.answers = { 1117: storeState }
    //for acute care - general treatment
    this.telehealthSurveyId = this.setAcuteCareSurveyId();

    this.selectedSurveyId = this.selectedSurvey ? this.selectedSurvey.surveyId : 0;
    //for acute care
    this.isSpinnerWait = true;

    this.getPharmacyDetails(this.telehealthSurveyId);

    this.surveyServices.getQuestionBySurveyDetailsId(this.telehealthSurveyId).subscribe(response => {
      if (response && response.data) {

        this.isDisplayPreviousButton = true;

        debugger;
        // acute care regular refill treatment
        if (this.selectedSurveyId == 12) {
          this.surveyServices.getAcuteCareRefillQuestions().subscribe(apiRes => {
            this.isSpinnerWait = false;
            if (apiRes && apiRes.data) {
              debugger;
              this.surveySteps = this.configurePharmacyStep(response.data);
              //this.surveySteps = response.data;
              let surveyStepsFromApi: SurveyStep[] = this.binCurrentStepsForAcuteCareRefill(apiRes.data.result);
              this.surveySteps.push(...surveyStepsFromApi);
              this.surveySteps = this.surveySteps.sort((a, b) => {
                if (a.tabOrder > b.tabOrder) {
                  return 1;
                }

                if (a.tabOrder < b.tabOrder) {
                  return -1;
                }

                return 0;
              });
              debugger;
              // store questions for future reference
              sessionStorage.setItem("_acuteCareStepsDetails", JSON.stringify(this.surveySteps))

              this.configureAcuteCareSteps(this.surveySteps);

            }
            else {
              //implement any error prompt
            }
          });
        }
        else {
          this.surveyServices.getAcuteCareQuestionDetails(this.selectedSurveyId.toString()).subscribe(apiRes => {
            this.isSpinnerWait = false;
            if (apiRes && apiRes.data) {
              debugger;
              this.surveySteps = this.configurePharmacyStep(response.data);
              //this.surveySteps = response.data;
              let surveyStepsFromApi: SurveyStep[] = this.binCurrentSteps(apiRes.data.result);
              this.surveySteps.push(...surveyStepsFromApi);
              // store questions for future reference
              sessionStorage.setItem("_acuteCareStepsDetails", JSON.stringify(this.surveySteps))

              this.configureAcuteCareSteps(this.surveySteps);

            }
            else {
              //implement any error prompt
            }

          });
        }


      }
      else {
        //implement any error prompt
      }
    });
  }

  configureAcuteCareSteps(steps: SurveyStep[]) {
    console.log(steps);
    this.maxStepIndex = (steps.length - 1);
    this.currentStep = steps[this.stepCounter];
    let fetchedAns: { [id: number]: string; } = JSON.parse(sessionStorage.getItem("patientAns"));
    if (fetchedAns) {
      this.answers = fetchedAns;
      this.stepCounter = steps.length - 1;
      this.currentStep = steps[this.stepCounter];
      this.formCompleted = true;
      this.isDisplayPreviousButton = true;
    }
    else if (this.currentStep.stepID == 1) {
      this.isDisplayPreviousButton = true;  //Change - To Show Previous Button on the 1st screen
    }
  }

  setAcuteCareSurveyId(): number {
    let id: number = 0;
    let AcuteCateSelectedSurvey: SurveyDetails = JSON.parse(sessionStorage.getItem("AcuteCateSelectedSurvey"));
    if (AcuteCateSelectedSurvey) {
      id = AcuteCateSelectedSurvey.surveyId;
    }
    else {
      id = 11;
    }
    return id
  }

  getPharmacyDetails(telehealthSurveyId: number) {
    let array: any[] = [];
    this.surveyServices.getAcuteCarePharmacyDetails(telehealthSurveyId).subscribe(res => {
      if (res && res.data) {
        this.acuteCarePharmacyList = res.data;
      }
      else {
        this.acuteCarePharmacyList = [];
      }
    });
  }

  configurePharmacyStep(steps: SurveyStep[]): SurveyStep[] {
    let pharmacyOptions: any[] = [];
    if (steps && steps.filter(f => f.stepID == 14).length > 0) {
      let pharmacyStep: SurveyStep = steps.filter(f => f.stepID == 14)[0];
      if (pharmacyStep && pharmacyStep.questions.filter(f => f.questionID == 11141).length > 0) {
        let dropdownQuestion = pharmacyStep.questions.filter(f => f.questionID == 11141)[0];
        if (this.acuteCarePharmacyList && this.acuteCarePharmacyList.length > 0) {
          //let options: SurveyOption[];
          this.acuteCarePharmacyList.forEach(f => {
            let option: SurveyOption = {
              isChildShow: false,
              isStopper: false,
              itemsInRow: 1,
              optionID: pharmacyStep.stepID,
              optionValue: f.name,
              questionID: dropdownQuestion.questionID,
              stepID: pharmacyStep.stepID
            };
            pharmacyOptions.push(option);
          });
        }
        steps.filter(f => f.stepID == 14)[0].questions.filter(f => f.questionID == 11141)[0].options = pharmacyOptions;
      }
    }
    return steps;
  }

  binCurrentSteps(data: any): SurveyStep[] {
    let list: SurveyStep[];
    if (data.length > 0) {
      list = [];
      let acuteQuestions: SurveyQuestion[] = [];
      data.forEach(f => {
        let optionarray = this.bindQuestionOptions(f.question_type, f.selection_option);
        let type = this.getQuestionType(f.question_type);
        let question: SurveyQuestion = {
          hint: f.help_text,
          itemsInRow: 1,
          options: optionarray,
          questionID: f.id,
          question: f.question,
          required: f.id == 54 ? false : true,
          stepID: 2,
          type: type,

        };
        acuteQuestions.push(question);

      });

      let step: SurveyStep = {
        stepCode: 2,
        stepID: 2,
        subTitle: "Please fill out a short questionnaire to help the Provider determine medical necessity for a medical prescriptions and medical advice.",
        title: this.selectedSurvey.surveyName,
        tabOrder: 2,
        questions: acuteQuestions
      };



      list.push(step);
      return list;
    }
  }

  binCurrentStepsForAcuteCareRefill(data: any): SurveyStep[] {
    let list: SurveyStep[];
    if (data.length > 0) {
      list = [];
      let acuteQuestions: SurveyQuestion[] = [];
      data.forEach(f => {
        let optionarray = this.bindQuestionOptionsForRefill(f.question_type, f.selection_option);
        let type = this.getQuestionType(f.question_type);
        let question: SurveyQuestion = {
          hint: f.help_text,
          itemsInRow: 1,
          options: optionarray,
          questionID: f.id,
          question: f.question,
          required: (f.id == 546 || f.id == 545) ? false : true,
          stepID: 2,
          type: type,
          isChild: type == "Radio" ? true : false
        };
        acuteQuestions.push(question);

      });

      let step: SurveyStep = {
        stepCode: 2,
        stepID: 2,
        subTitle: "Please fill out a short questionnaire to help the Provider determine medical necessity for a medical prescriptions and medical advice.",
        title: "Regular medicine refill",
        tabOrder: 15,
        questions: acuteQuestions
      };



      list.push(step);
      return list;
    }
  }

  getQuestionType(type: string): string {
    debugger;
    if (type == "YesNo") {
      type = "Radio";
    }
    if (type == "Selection") {
      type = "Select";
    }
    if (type == "MultipleSelection") {
      type = "multi-select";
    }
    return type;
  }

  bindQuestionOptions(type: string, optns: any[]): any[] {
    let optionarray = [];
    if (type == "YesNo") {
      let option1 = {
        optionID: 1,
        optionValue: "Yes",
        itemsInRow: 1
      };
      let option2 = {
        optionID: 2,
        optionValue: "No",
        itemsInRow: 1
      };
      optionarray.push(option1);
      optionarray.push(option2);
    }
    if (type == "Selection" || type == "MultipleSelection") {
      if (optns.length > 0) {
        optns.forEach((value, index) => {
          let option = {
            optionID: index,
            optionValue: value,
            itemsInRow: 1
          };
          optionarray.push(option);
        });
      }
    }
    return optionarray;
  }

  bindQuestionOptionsForRefill(type: string, optns: any[]): any[] {
    let optionarray = [];
    if (type == "YesNo") {
      let option1 = {
        optionID: 1,
        optionValue: "Yes",
        itemsInRow: 1,
        isChildShow: true
      };
      let option2 = {
        optionID: 2,
        optionValue: "No",
        itemsInRow: 1
      };
      optionarray.push(option1);
      optionarray.push(option2);
    }
    if (type == "Selection" || type == "MultipleSelection") {
      if (optns.length > 0) {
        optns.forEach((value, index) => {
          let option = {
            optionID: index,
            optionValue: value,
            itemsInRow: 1
          };
          optionarray.push(option);
        });
      }
    }
    return optionarray;
  }

  previousClick() {
    debugger;
    this.stepCounter = this.stepCounter - 1;
    if (this.stepCounter == -1) {
      this.router.navigate(["/acute-care"]);
    }
    else {
      this.currentStep = this.surveySteps[this.stepCounter];
      this.checkPreviousShow(this.stepCounter);
      this.checkNextButtonShow(this.stepCounter);
    }

  }

  nextClick() {
    let serveyDetails = JSON.parse(sessionStorage.getItem("selectedSurvey"));

    this.formDataCaptureString = "";

    this.formDataCaptureString = this.formDataCaptureString + JSON.stringify(this.answers);

    Object.keys(this.form.controls).forEach(key => {
      if (this.countForEmail == 12) {
        //console.log(this.form.controls[key]);
        this.emailValue = this.form.controls[key].value;
      }
      if (this.countForEmail == 10) {
        this.phoneValue = this.form.controls[key].value;
      }
      this.form.controls[key].markAsTouched();

      this.countForEmail++;
    });
    if (this.form.valid) {
      debugger;
      if (Number(serveyDetails.surveyTypeCode) != 5 &&
        (
          Number(serveyDetails.surveyId) != 2 &&
          Number(serveyDetails.surveyId) != 7 &&
          Number(serveyDetails.surveyId) != 10 &&
          Number(serveyDetails.surveyId) != 12
        )) {
        debugger;
        if (this.stepCounter == 0) {
          this.adminService.checkNonRefundedAccountByPatientEmail(this.emailValue).subscribe(res => {
            if (res && res.data) {
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                  message: "Your email address has been blocked due to refund policy.",
                  buttonText: {
                    ok: 'OK',
                    cancel: ''
                  }
                }
              });

              dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                this.router.navigate(['/intakeform/selector']);
              });
            }
            else {
              const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                data: {
                  message: "Please confirm you have entered correct mobile number (Not a landline number).",
                  buttonText: {
                    ok: 'I confirm my mobile number',
                    cancel: 'Cancel'
                  }
                }
              });

              dialogRef.afterClosed().subscribe((confirmed: boolean) => {
                if (confirmed) {
                  const a = document.createElement('a');
                  a.click();
                  a.remove();
                  console.log(this.stepCounter);

                  this.proceedToNext();


                  //Entry to log
                  var logGuid = localStorage.getItem("logGuid");
                  if (logGuid == null || logGuid == '')
                    logGuid = '';
                  else
                    localStorage.removeItem("logGuid");

                  var logModel: IntakeFormLogModel = {
                    logConnId: logGuid,
                    userPhone: this.phoneValue,
                    userEmail: this.emailValue,
                    userAction: serveyDetails ? serveyDetails.surveyTypeName : '' + "->" + (serveyDetails ? serveyDetails.surveyName : '') + "-> Clicked On Next with step counter -> " + this.stepCounter,
                    userActionCode: 200,
                    ipAddress: localStorage.getItem("clientIp"),
                    browserName: this.adminService.detectBrowserName(),
                    browserVersion: this.adminService.detectBrowserVersion(),
                    clientMetaData: this.adminService.detectClientDeviceMetadata(),
                    formData: "Steps: " + this.formDataCaptureString,
                  }
                  this.insertIntakeFormLog(logModel);


                }
              });
            }
          });

        }
        else {
          this.proceedToNext();
          var logGuid = localStorage.getItem("logGuid");
          if (logGuid == null || logGuid == '')
            logGuid = '';
          else
            localStorage.removeItem("logGuid");

          var logModel: IntakeFormLogModel = {
            logConnId: logGuid,
            userPhone: this.phoneValue,
            userEmail: this.emailValue,
            userAction: serveyDetails ? serveyDetails.surveyTypeName : '' + "->" + (serveyDetails ? serveyDetails.surveyName : '') + "-> Clicked On Next with step counter -> " + this.stepCounter,
            userActionCode: 200,
            ipAddress: localStorage.getItem("clientIp"),
            browserName: this.adminService.detectBrowserName(),
            browserVersion: this.adminService.detectBrowserVersion(),
            clientMetaData: this.adminService.detectClientDeviceMetadata(),
            formData: "Steps: " + this.formDataCaptureString,
          }
          this.insertIntakeFormLog(logModel);
        }
      }
      else {


        //Entry to log
        var logGuid = localStorage.getItem("logGuid");
        if (logGuid == null || logGuid == '')
          logGuid = '';
        else
          localStorage.removeItem("logGuid");

        if (this.emailValue == null || this.emailValue == undefined ||
          this.emailValue.toUpperCase() == "NO" || this.emailValue.toUpperCase() == "YES") //This is for COVID Treatment with Symptoms Already registered patient - Here options will have yes / no values only 
          this.emailValue = localStorage.getItem("intakeFormEmail");

        var logModel: IntakeFormLogModel = {
          logConnId: logGuid,
          userPhone: '', //Phone no. could not be fetched
          userEmail: this.emailValue,
          userAction: serveyDetails ? serveyDetails.surveyTypeName : '' + "->" + (serveyDetails ? serveyDetails.surveyName : '') + "-> Clicked On Next with step counter -> " + this.stepCounter,
          userActionCode: 200,
          ipAddress: localStorage.getItem("clientIp"),
          browserName: this.adminService.detectBrowserName(),
          browserVersion: this.adminService.detectBrowserVersion(),
          clientMetaData: this.adminService.detectClientDeviceMetadata(),
          formData: "Steps: " + this.formDataCaptureString,
        }
        this.insertIntakeFormLog(logModel);

        console.log(this.stepCounter);
        this.proceedToNext();

      }

    }
    else {
      setTimeout(() => {
        window.scroll({ top: (window.innerHeight / 2.6) });

      });
      alert("please fill up all the required field.");
    }
  }

  proceedToNext() {
    this.stepCounter = this.stepCounter + 1;
    this.currentStep = this.surveySteps[this.stepCounter];

    this.checkPreviousShow(this.stepCounter);
    this.checkNextButtonShow(this.stepCounter);
    window.scroll(0, 0);
  }

  checkPreviousShow(stepCounter) {
    //Change for always show previous button
    if (stepCounter > 0) {
      this.isDisplayPreviousButton = true;
    }
    else {
      this.isDisplayPreviousButton = true;
    }
  }
  checkNextButtonShow(stepCounter) {
    if (stepCounter < this.maxStepIndex) {
      this.isDisplayNextButton = true;
    }
    else {
      this.isDisplayNextButton = false;
    }
  }

  previousReviewClick() {
    debugger;
    this.formCompleted = false;
    this.checkNextButtonShow(this.stepCounter);

  }

  submitClick() {
    debugger;
    if (this.selectedSurvey.surveyId == 12 && this.savedFilesName == "" && this.medicineFiles.length > 0) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          message: "Are you sure you want to upload the files?",
          buttonText: {
            ok: 'OK',
            cancel: 'Cancel'
          }
        }
      })
      dialogRef.afterClosed().subscribe((confirmed: boolean) => {
        if (confirmed) {
          const a = document.createElement('a');
          a.click();
          a.remove();
          this.medicineUploadClick();
        }

      });

    }
    else {
      this.reviewFinalSurveyForm();
    }

  }

  reviewFinalSurveyForm() {
    Object.keys(this.form.controls).forEach(key => {
      this.form.controls[key].markAsTouched();
    });

    let serveyDetails = JSON.parse(sessionStorage.getItem("selectedSurvey"));
    if (serveyDetails.surveyId == 2 || serveyDetails.surveyId == 7 || serveyDetails.surveyId == 10) {
      let existingSubmissionId = sessionStorage.getItem("existingSubmissionId");
      console.log(existingSubmissionId);

      this.surveyServices.getExistingSurveyAsnwerBySubmissionId(Number(existingSubmissionId), serveyDetails.surveyId)
        .subscribe(res => {
          if (res.data != null) {
            res.data.forEach(q => {
              this.answers[q.questionId] = q.answer;
            });

          }
        });
    }
    this.surveySteps.forEach(step => {
      if (step.questions) {
        step.questions.forEach(question => {
          if (question.type == 'Date')
            this.answers[question.questionID] = new DatePipe("en-us").transform(this.answers[question.questionID], 'MM/dd/yyyy');
        });
      }
    });

    if (this.form.valid) {
      this.formCompleted = true;
    }
  }

  stepHasAnswers(step) {
    return step.questions.some(q => {
      if (this.answers[q.questionID])
        return true;
    });
  }

  confirmClick() {
    this.formDataCaptureString = "";

    this.formDataCaptureString = this.formDataCaptureString + JSON.stringify(this.answers);


    let serveyDetails = JSON.parse(sessionStorage.getItem("selectedSurvey"));
    let patId: number = 0;
    if (serveyDetails != null && (serveyDetails.surveyId == 2 || serveyDetails.surveyId == 7 || serveyDetails.surveyId == 10)) {
      let existingPatientId = sessionStorage.getItem("existingPatientId");
      patId = Number(existingPatientId);
    }
    if (serveyDetails.surveyTypeCode == 5) {
      this.acuteCareModel = this.bindAcuteCareModel();
      sessionStorage.setItem("_acuteCare", JSON.stringify(this.acuteCareModel));
      sessionStorage.setItem("_acuteAnswer", JSON.stringify(this.answers));
      sessionStorage.setItem("_acuteAnswerDesc", JSON.stringify(this.descAnswer));
      this.sharedService.setSurveySWubmissionDetails(this.acuteCareModel.surveySubmissionDetails);
      this.router.navigate(['/intakeform/payment']);
    }
    else {
      this.surveyServices.createSurveySubmission(this.answers).subscribe(res => {
        if (res.data > 0) {
          sessionStorage.setItem("patientAns", JSON.stringify(this.answers));
          this.surveySubmissionDetails = {
            submissionId: res.data,
            patientDetails: {
              patientId: patId,
              firstName: this.answers[1],
              middleName: this.answers[2],
              lastName: this.answers[3],
              phoneNo: this.answers[10],
              email: this.answers[12],
              address: this.answers[5],
              state: this.answers[7],
              city: this.answers[6],
              zip: this.answers[8],
              zipCode: this.answers[8],
              gendar: this.answers[4],
              gender: this.answers[4],
              dob: this.answers[9],
              height: this.answers[15],
              weight: this.answers[16].toString(),
            },
            surveyDetails: JSON.parse(sessionStorage.getItem("selectedSurvey"))
          };
          console.log(this.surveySubmissionDetails);
          this.sharedService.setSurveySWubmissionDetails(this.surveySubmissionDetails);

          //Entry to log
          var logGuid = localStorage.getItem("logGuid");
          if (logGuid == null || logGuid == '')
            logGuid = '';
          else
            localStorage.removeItem("logGuid");

          var logModel: IntakeFormLogModel = {
            logConnId: logGuid,
            userPhone: this.surveySubmissionDetails.patientDetails.phoneNo,
            userEmail: this.surveySubmissionDetails.patientDetails.email, //serveyDetails.email,  
            userAction: serveyDetails ? serveyDetails.surveyTypeName : '' + "->" + (serveyDetails ? serveyDetails.surveyName : '') + "-> Clicked to the payment page.",
            userActionCode: 200,
            ipAddress: localStorage.getItem("clientIp"),
            browserName: this.adminService.detectBrowserName(),
            browserVersion: this.adminService.detectBrowserVersion(),
            clientMetaData: this.adminService.detectClientDeviceMetadata(),
            formData: "Steps: " + this.formDataCaptureString,
          }
          this.insertIntakeFormLog(logModel);



          this.router.navigate(['/intakeform/payment']);
        }
      });
    }
  }

  openAlertDialog(alertMessage: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: alertMessage,
        buttonText: {
          cancel: 'OK'
        }
      },
    });
  }

  insertIntakeFormLog(logModel: IntakeFormLogModel) {
    this.adminService.InsertIntakeFormLog(logModel).subscribe(data => {
      if (data.error == null) {
        localStorage.setItem('logGuid', JSON.stringify(data.data));

      }
      else {
        //error in log
      }
    },
      //error => {

      //}
    );
  }

  checkDropdownSelectChange(question: SurveyQuestion, event) {
    console.log(event);

    console.log();
    if (question.questionID == 11141) {
      if (this.acuteCarePharmacyList.filter(f => f.name == event.value).length > 0) {
        let pharmacyDetails = this.acuteCarePharmacyList.filter(f => f.name == event.value)[0];
        if (pharmacyDetails.name != "" && pharmacyDetails.phone != "" && pharmacyDetails.fax) {
          this.answers[11142] = pharmacyDetails.name;
          this.answers[11177] = pharmacyDetails.phone;
          this.answers[11178] = pharmacyDetails.fax;

          setTimeout(() => {
            this.textInputs.toArray().forEach(f => {
              f.nativeElement.setAttribute("readonly", true);
            });
          });
        }
        else {
          this.answers[11142] = "";
          this.answers[11177] = "";
          this.answers[11178] = "";
          setTimeout(() => {
            this.textInputs.toArray().forEach(f => {
              f.nativeElement.removeAttribute("readonly");
            });
          });

        }
      }
      else {
        setTimeout(() => {
          this.textInputs.toArray().forEach(f => {
            f.nativeElement.removeAttribute("readonly");
          });
        });
      }

    }

  }

  radioChange(question, event) {
    debugger;
    let selectOptionDetails = question.options.filter(f=>f.optionValue == event.value);
    if(selectOptionDetails[0].isChildShow) {
      question.initialView = true;
    }
    else {
      question.initialView = false;
    }
  }

  showStopper(event) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: "For your safety, we advise you to seek your primary care doctor.",
        buttonText: {
          ok: 'OK',
          cancel: ''
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const a = document.createElement('a');
        a.click();
        a.remove();
        this.router.navigate(['/acute-care']);
      }
      else {
        event.value = "";
      }
    });
  }

  bindAcuteCareModel(): AcuteCareModel {
    let acuteCare: AcuteCareModel;
    acuteCare = {
      pharmacyDetails: {
        pharmacyFax: "FAX",
        pharmacyName: "TestPharmacy",
        pharmacyPhone: "Test Phone"
      },
      surveySubmissionDetails: {
        submissionId: 0,
        patientDetails: {
          patientId: 0,
          firstName: this.answers[1111],
          middleName: this.answers[1112],
          lastName: this.answers[1113],
          phoneNo: this.answers[11110],
          email: this.answers[11112],
          address: this.answers[1115],
          state: this.answers[1117],
          city: this.answers[1116],
          zip: this.answers[1118],
          zipCode: this.answers[1118],
          gendar: this.answers[1114],
          gender: this.answers[1114],
          dob: this.answers[1119],
          height: '',
          weight: '',
        },
        surveyDetails: JSON.parse(sessionStorage.getItem("selectedSurvey"))
      },
      surveyPaymentDetails: null,
      questionierDetails: null
    }
    return acuteCare;
  }

  getMedicineFileDetails(event) {
    debugger;
    if (this.medicineFiles) {
      for (var i = 0; i < event.target.files.length; i++) {
        if(this.medicineFiles.map(m => m.name).indexOf(event.target.files[i].name) <= -1) {
          this.medicineFiles.push(event.target.files[i]);
        }
        else {
          this.openAlertDialog("File already been selected.");
        }
        
      }
      console.log(this.medicineFiles);
    }
    else {
      this.openAlertDialog("Please select a file to upload");
    }

    this.medicineFileInput.nativeElement.value = "";

  }

  medicineUploadClick() {
    debugger;
    this.isSpinnerWait = true;
    const frmData = new FormData();
    if (this.medicineFiles && this.medicineFiles.length > 0) {
      for (var i = 0; i < this.medicineFiles.length; i++) {
        frmData.append("medicineFiles", this.medicineFiles[i]);
      }

      this.surveyServices.medicinefileUpload(frmData).subscribe(res => {
        this.isSpinnerWait = false;
        if (res && res.data) {
          const savedFiles = JSON.stringify(res.data);
          this.savedFilesName = savedFiles;
          window.sessionStorage.setItem("_acuteCareMedicineFiles", savedFiles);
          this.openAlertDialog("file uploaded successfully.");
          this.reviewFinalSurveyForm();
        }
        else {
          this.openAlertDialog("error in file upload");
        }
      });
    }
    else {
      this.isSpinnerWait = false;
      this.openAlertDialog("Pease select file to upload.");
    }



  }

  deleteLicenseFile(file: any) {
    const index = this.medicineFiles.indexOf(file);
    if (index > -1) { // only splice array when item is found
      this.medicineFiles.splice(index, 1); // 2nd parameter means remove one item only
    }
    this.medicineFileInput.nativeElement.value = "";
  }

  showSampleMedicineImages() {
    const dialogRef = this.dialog.open(RegularSampleMedicineComponent, {
      data: {
        message: "Your email address has been blocked due to refund policy.",
        buttonText: {
          ok: 'OK',
          cancel: ''
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      
        const a = document.createElement('a');
        a.click();
        a.remove();


      
    });
  }



}
