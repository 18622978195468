import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdminServicesService } from 'src/app/api-services/admin-services/admin-services.service';
import { InvoicePreviewModel } from 'src/app/Models/survey-details';

@Component({
  selector: 'app-payment-confirm',
  templateUrl: './payment-confirm.component.html',
  styleUrls: ['./payment-confirm.component.sass']
})
export class PaymentConfirmComponent implements OnInit {
  invoiceData: InvoicePreviewModel[];
  totalAmount: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: InvoicePreviewModel[], public adminService: AdminServicesService, 
  private route: Router, private dialog: MatDialog, public dialogRef: MatDialogRef<any>, private datepipe: DatePipe) { }

  ngOnInit(): void {
    this.invoiceData = this.data;
    console.log(this.invoiceData);
    this.totalAmount = this.invoiceData
    .map(a => a.amount)
    .reduce(function (a, b) { return a + b; });
  }

  closePayNowPopup(opFlag: boolean) {
    this.dialogRef.close(opFlag);
  }

}
