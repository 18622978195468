import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User } from '../models/user';

import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    //debugger;
    if(!localStorage.getItem("currentUser")) {
      this.router.navigate(['/authentication/signin']);
    }
    else {
      let currentUserValue: User = JSON.parse(localStorage.getItem("currentUser"));
      const userRole = currentUserValue.role;
      if (route.data.role && !route.data.role.some(s => s == userRole)) {
        this.router.navigate(['/authentication/signin']);
      }
      return true;
    }
    
  }
}
