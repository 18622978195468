<div class="wrapper">
    <mat-card class="container">
        <div class="row">
            <div class="col-sm-12">
                <mat-progress-bar mode="indeterminate" *ngIf="isSpinnerWait"></mat-progress-bar>
            </div>
        </div>
        <h3 class="text-center" style="color: #4043ac;">Welcome to Telehealth Services </h3>
        <h3 class="text-center" style="color: #4043ac;">with Dr Stella Immanuel MD </h3>
        <!-- <p class="text-center" style="margin-bottom: 0;">$90 for prevention for Covid-19</p>
        <p class="text-center">$185 for sick patients! Includes up to 3 follow up visits</p> -->
        <ng-container *ngIf="!formCompleted">
            <mat-card-title>Step {{ (stepCounter + 1) + " " + currentStep.title }}</mat-card-title>
            <mat-card-subtitle>{{ currentStep.subTitle }}</mat-card-subtitle>
        </ng-container>
        <mat-card-content>

            <ng-container *ngIf="currentStep && !formCompleted" #scrollPanel>
                <form class="form Step_frm" #form="ngForm">
                    <div class="col-sm-12 text-right btn_div clearfix">
                        <!-- <button mat-raised-button color="primary" *ngIf="isDisplayPreviousButton" (click)="previousClick()" class="pull-left">Previous</button>&nbsp;
                        <button mat-raised-button color="primary" *ngIf="isDisplayNextButton" [disabled]="isNextButtonDisabled" (click)="nextClick()" class="pull-right">Next</button>
                        <button mat-raised-button color="primary" *ngIf="!isDisplayNextButton" (click)="submitClick()" class="pull-right">Review Your Submission</button> -->
                    </div>
                    <div class="row">
                        <div *ngFor="let question of currentStep.questions" [class.col-sm-12]="question.itemsInRow == 1"
                            [class.col-sm-6]="question.itemsInRow == 2" [class.col-sm-4]="question.itemsInRow == 3">
                            <div class="row">
                                <ng-container [ngSwitch]="question.type">
                                    <div></div>
                                    <mat-form-field *ngSwitchCase="'Text'" class="col-sm-12">
                                        <mat-label>
                                            <span [innerHtml]="question.question"></span>
                                        </mat-label>
                                        <input #textInputs matInput style="width:100%;" #controlid="ngModel" type="text"
                                            [name]="'question-' + question.questionID"
                                            [(ngModel)]="answers[question.questionID]" [required]="question.required"
                                            [pattern]="question.regEx">
                                        <mat-hint align="start" *ngIf="question.hint" style="margin-bottom:10px;">{{
                                            question.hint }}</mat-hint>
                                        <mat-error *ngIf="controlid?.errors?.required"><strong>This field is
                                                required</strong></mat-error>
                                        <mat-error
                                            *ngIf="controlid?.errors?.pattern"><strong>invalid</strong></mat-error>
                                    </mat-form-field>

                                    <mat-form-field *ngSwitchCase="'ReadText'" class="col-sm-12">
                                        <mat-label>{{ question.question }}</mat-label>
                                        <input matInput style="width:100%" #controlid="ngModel" type="text"
                                            [name]="'question-' + question.questionID"
                                            [(ngModel)]="answers[question.questionID]" [value]="patientState" readonly>

                                    </mat-form-field>

                                    <ng-container *ngSwitchCase="'Notes'">
                                        <div class="form-field-name" [class.col-sm-12]="question.itemsInRow == 1"
                                            [class.col-sm-4]="question.itemsInRow == 2">{{ question.question }}
                                        </div>
                                    </ng-container>

                                    <mat-form-field *ngSwitchCase="'Number'" class="col-sm-12">
                                        <mat-label>{{ question.question }}</mat-label>
                                        <input matInput style="width:100%" #controlid="ngModel" type="number"
                                            [name]="'question-' + question.questionID"
                                            [(ngModel)]="answers[question.questionID]" [required]="question.required"
                                            [pattern]="question.regEx">
                                        <mat-hint align="start" *ngIf="question.hint" style="margin-bottom:10px;">{{
                                            question.hint }}</mat-hint>
                                        <mat-error *ngIf="controlid?.errors?.required">
                                            <strong>This field is required</strong>
                                        </mat-error>
                                        <mat-error
                                            *ngIf="controlid?.errors?.pattern"><strong>invalid</strong></mat-error>
                                    </mat-form-field>

                                    <mat-form-field *ngSwitchCase="'Date'" class="col-sm-12">
                                        <mat-label>
                                            <!-- {{ question.question }} -->
                                            <div [innerHTML]="question.question | safeHtml"></div>
                                        </mat-label>
                                        <input matInput style="width:100%" #controlid="ngModel"
                                            [name]="'question-' + question.questionID"
                                            [(ngModel)]=" answers[question.questionID]" placeholder="MM/DD/YYYY"
                                            (focus)="picker.open()" [required]="question.required"
                                            [matDatepicker]="picker">
                                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                        <mat-hint align="start" *ngIf="question.hint" style="margin-bottom:10px;">{{
                                            question.hint }}</mat-hint>
                                        <mat-error *ngIf="controlid?.errors?.required"><strong>This field is
                                                required</strong></mat-error>
                                        <mat-error *ngIf="controlid?.errors?.pattern"><strong>{{ controlid?.value
                                                }}</strong></mat-error>
                                    </mat-form-field>

                                    <mat-form-field *ngSwitchCase="'Email'" class="col-sm-12">
                                        <mat-label>{{ question.question }}</mat-label>
                                        <input matInput style="width:100%" #controlid="ngModel" type="email"
                                            [name]="'question-' + question.questionID"
                                            [(ngModel)]="answers[question.questionID]" [required]="question.required"
                                            [pattern]="question.regEx">
                                        <mat-hint align="start" *ngIf="question.hint" style="margin-bottom:10px;">{{
                                            question.hint }}</mat-hint>
                                        <mat-error *ngIf="controlid?.errors?.required"><strong>This field is
                                                required</strong></mat-error>
                                        <mat-error *ngIf="controlid?.errors?.pattern"><strong>invalid
                                                email</strong></mat-error>
                                    </mat-form-field>

                                    <mat-form-field *ngSwitchCase="'Select'" class="col-sm-12">
                                        <mat-label>{{ question.question }}</mat-label>
                                        <mat-select [name]="'question-' + question.questionID"
                                            (selectionChange)="checkDropdownSelectChange(question, $event)"
                                            #controlid="ngModel" [(ngModel)]="answers[question.questionID]"
                                            [required]="question.required">
                                            <mat-option *ngFor="let option of question.options"
                                                [value]="option.optionValue">
                                                {{option.optionValue}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="controlid?.touched && controlid?.errors?.required">
                                            <strong>This field is required</strong>
                                        </mat-error>
                                    </mat-form-field>

                                    <mat-form-field *ngSwitchCase="'multi-select'" class="col-sm-12">
                                        <mat-label>{{ question.question }}</mat-label>
                                        <mat-select multiple [name]="'question-' + question.questionID"
                                            (selectionChange)="checkDropdownSelectChange(question, $event)"
                                            #controlid="ngModel" [(ngModel)]="answers[question.questionID]"
                                            [required]="question.required">
                                            <mat-option *ngFor="let option of question.options"
                                                [value]="option.optionValue">
                                                {{option.optionValue}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="controlid?.touched && controlid?.errors?.required">
                                            <strong>This field is required</strong>
                                        </mat-error>
                                    </mat-form-field>

                                    <ng-container *ngSwitchCase="'Radio'" class="">
                                        <div class="mat-form-field col-sm-12 gender_section">
                                            <div class="row">
                                                <div class="form-field-name"
                                                    [class.col-sm-12]="question.options[0].itemsInRow == 1"
                                                    [class.col-sm-4]="question.options[0].itemsInRow == 2"
                                                    [class.mat-error]="controlid?.touched && controlid?.errors?.required">
                                                    {{ question.question }}<ng-container
                                                        *ngIf="question.required">*</ng-container>
                                                    <mat-error *ngIf="controlid?.touched && controlid?.errors?.required"
                                                        style="display: block; ">
                                                        <strong>This field is required</strong>
                                                    </mat-error>

                                                </div>
                                                <mat-radio-group #controlid="ngModel"
                                                    [name]="'question-' + question.questionID"
                                                    [(ngModel)]="answers[question.questionID]"
                                                    [required]="question.required"
                                                    [class.col-sm-12]="question.options[0].itemsInRow == 1"
                                                    [class.col-sm-8]="question.options[0].itemsInRow == 2">
                                                    <div *ngIf="question.questionID == 1114; else otherContent">
                                                        <mat-radio-button *ngFor="let option of patientGender"
                                                            [value]="option.optionValue"
                                                            [class.col-sm-12]="option.itemsInRow == 1"
                                                            [class.col-sm-4]="option.itemsInRow == 2"
                                                            style="height:25px;margin-left: 16px"
                                                            (change)="radioChange(question, $event)">
                                                            {{option.optionValue}}</mat-radio-button>
                                                    </div>

                                                    <ng-template #otherContent>
                                                        <mat-radio-button *ngFor="let option of question.options"
                                                            [value]="option.optionValue"
                                                            [class.col-sm-12]="option.itemsInRow == 1"
                                                            [class.col-sm-4]="option.itemsInRow == 2"
                                                            style="height:25px;margin-left: 16px"
                                                            (change)="radioChange(question, $event)">
                                                            {{option.optionValue}}</mat-radio-button>
                                                    </ng-template>

                                                </mat-radio-group>
                                                <mat-hint
                                                    style="font-size:80%;margin-left:20px;margin-top:0px;margin-bottom:10px;"
                                                    *ngIf="question.hint">{{ question.hint }}</mat-hint>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="'Check'">
                                        <div class="mat-form-field col-sm-12 agree_section">
                                            <div class="row">
                                                <div class="form-field-name"
                                                    [class.col-sm-12]="question.options[0].itemsInRow == 1"
                                                    [class.col-sm-6]="question.options[0].itemsInRow == 2"
                                                    [class.mat-error]="controlid?.touched && controlid?.errors?.required">
                                                    {{ question.question }}<ng-container
                                                        *ngIf="question.required">*</ng-container>

                                                    <mat-error *ngIf="controlid?.touched && controlid?.errors?.required"
                                                        style="display: block; ">
                                                        <strong>This field is required</strong>
                                                    </mat-error>
                                                </div>
                                                <mat-selection-list #controlid="ngModel"
                                                    [name]="'question-' + question.questionID"
                                                    [(ngModel)]="answers[question.questionID]"
                                                    [required]="question.required">
                                                    <mat-list-option checkboxPosition="before"
                                                        *ngFor="let option of question.options"
                                                        [value]="option.optionValue"
                                                        [class.col-sm-4]="option.itemsInRow == 3"
                                                        [class.col-sm-12]="option.itemsInRow == 1"
                                                        [class.col-sm-6]="option.itemsInRow == 2" style="height:25px">
                                                        {{option.optionValue}}
                                                    </mat-list-option>
                                                </mat-selection-list>

                                                <mat-hint class="col-sm-12"
                                                    style="font-size:80%;margin-left:20px;margin-top:0px; margin-bottom:10px;"
                                                    *ngIf="question.hint">{{ question.hint }}</mat-hint>
                                            </div>
                                        </div>
                                    </ng-container>

                                    
                                    <mat-form-field *ngSwitchCase="'TextArea'" class="col-sm-12">
                                        <mat-label>{{ question.question }}</mat-label>
                                        <textarea matInput style="width:100%" #controlid="ngModel" type="text" [name]="'question-' + question.questionID" 
                                        [(ngModel)]="answers[question.questionID]" [required]="question.required"
                                        [pattern]="question.regEx"></textarea>
                                        <mat-hint align="start" *ngIf="question.hint" style="margin-bottom:10px;">{{ question.hint }}</mat-hint>
                                        <mat-error *ngIf="controlid?.errors?.required">
                                            <strong>This field is required</strong>
                                        </mat-error>
                                        <mat-error *ngIf="controlid?.errors?.pattern"><strong>invalid</strong></mat-error>
                                    </mat-form-field>

                                    <ng-container *ngSwitchCase="'file'"
                                        style="border: 1px solid blue; border-radius: 5px; padding: 10px;">
                                        <div class="col-md-12" style="margin-bottom: 0px !important;">
                                            <div class="col-md-12" style="margin-top: 10px;"
                                                *ngFor="let file of medicineFiles">
                                                <div class="row">
                                                    <div class="col-md-10" style="word-wrap: break-word;">
                                                        {{file.name}} - {{file.type}} - {{file.size}}
                                                    </div>
                                                    <div class="col-md-2" *ngIf="savedFilesName == ''">
                                                        <button type="button" mat-mini-fab color="warn"
                                                            (click)="deleteLicenseFile(file)">
                                                            <mat-icon>delete</mat-icon>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12" style="margin-bottom: 0px !important;"
                                            *ngIf="savedFilesName == ''">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <mat-label>{{ question.question }}<span style="color: red;">*
                                                        </span>
                                                    </mat-label>
                                                </div>
                                                <div class="col-md-3">
                                                    <button type="button" mat-mini-fab color="primary"
                                                        (click)="fileInput.click()">
                                                        <mat-icon>attach_file</mat-icon>
                                                        <input #fileInput type="file" multiple="multiple"
                                                            (change)="getMedicineFileDetails($event)"
                                                            style="display:none;" />
                                                    </button>
                                                </div>
                                                <div class="col-md-3" style="text-align: center;">
                                                    <button mat-raised-button color="primary"
                                                        [disabled]="(medicineFiles && medicineFiles.length < 2) || (savedFilesName != '' || isSpinnerWait)"
                                                        (click)="medicineUploadClick()" class="pull-right">
                                                        <mat-icon *ngIf="isSpinnerWait">
                                                            <mat-spinner diameter="20">
                                                            </mat-spinner>
                                                        </mat-icon>
                                                        Upload
                                                    </button>
                                                </div>
                                                <div class="col-md-12">
                                                    <a href="javascript:void(0)" (click)="showSampleMedicineImages()">Sample Image</a>
                                                </div>
                                            </div>
                                        </div>


                                    </ng-container>

                                </ng-container>
                                <div class="row">
                                    <div *ngIf="question.isChild && selectedSurvey.surveyId == 12" class="col-md-12">
                                        <mat-form-field *ngIf="question.initialView" style="width: 100%;" #myElement>
                                            <mat-label>Description</mat-label>
                                            <input matInput style="width:100%" #controlid="ngModel" type="text"
                                                [name]="'question-' + question.questionID + '-desc'"
                                                [(ngModel)]="descAnswer[question.questionID + '-desc']"
                                                required>
                                            <mat-error *ngIf="controlid?.touched && controlid?.errors?.required">
                                                <strong>This field is required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                

                            </div>
                        </div>
                    </div>
                </form>
                <div class="col-sm-12 text-right btn_div clearfix">
                    <button mat-raised-button color="primary" *ngIf="isDisplayPreviousButton" (click)="previousClick()"
                        class="pull-left">Previous</button>&nbsp;
                    <button mat-raised-button color="primary" *ngIf="isDisplayNextButton" (click)="nextClick()"
                        [disabled]="isNextButtonDisabled" class="pull-right">Next</button>
                    <button mat-raised-button color="primary" [disabled]="selectedSurvey.surveyId == 12 && medicineFiles.length < 2" *ngIf="!isDisplayNextButton" (click)="submitClick()"
                        class="pull-right">Review Your Submission</button>
                </div>
            </ng-container>
            <ng-container *ngIf="formCompleted">
                <div class="col-sm-12 text-right" style="min-height: 50px;">
                    <button mat-raised-button color="primary" *ngIf="isDisplayPreviousButton"
                        (click)="previousReviewClick()">Previous</button>&nbsp;
                    <button mat-raised-button color="primary" (click)="confirmClick()" style="margin-top: 5px;">Final
                        Step : Pay and Submit Application</button>
                </div>
                <div *ngFor="let step of surveySteps" class="row step-2-class">
                    <ng-container *ngIf="stepHasAnswers(step)">
                        <h5 class="col-sm-12">{{ step.title }}</h5>
                        <div *ngFor="let question of step.questions" [class.col-sm-4]="question.itemsInRow == 3"
                            [class.col-sm-12]="question.itemsInRow == 1" [class.col-sm-6]="question.itemsInRow == 2">
                            <ng-container
                                *ngIf=" question.type != 'Notes' && question.type != 'Date' && answers[question.questionID] ">
                                <label>{{ question.question }}</label> : <strong> {{ answers[question.questionID]
                                    }}</strong>
                            </ng-container>
                            <ng-container *ngIf=" question.type == 'Date' ">
                                <label>{{ question.question }}</label> : <strong> {{ answers[question.questionID] | date
                                    : 'MM/dd/yyyy' }}</strong>
                            </ng-container>

                            <ng-container *ngIf=" question.type == 'Notes' ">
                                <div innerHTML="{{question.question}}"></div>
                                <!-- <div ></div> -->
                                <!-- <label>{{ question.question }}</label> -->
                            </ng-container>
                        </div>
                        <div class="col-sm-12">
                            <hr>
                        </div>
                    </ng-container>
                </div>
                <div class="col-sm-12 text-right">
                    <button mat-raised-button color="primary" *ngIf="isDisplayPreviousButton"
                        (click)="previousReviewClick()">Previous</button>&nbsp;
                    <button mat-raised-button color="primary" (click)="confirmClick()" style="margin-top: 5px;">Final
                        Step : Pay and Submit Application</button>
                </div>
            </ng-container>
            <hr>
        </mat-card-content>
        <mat-card-footer>
            <div class="row">
                <h5 style="color: red;">
                    <strong class="blink_text">In case of difficulty in filling up the form, please email us
                        at..</strong>
                    <h4>
                        <a style="font-weight: bold; font-size: 18px !important;"
                            href="mailto:patienthelpdesk@drstellamd.com">patienthelpdesk@drstellamd.com</a>
                        <br />
                        <a style="font-weight: bold; font-size: 18px !important;" href="tel:+832-808-5574">Telephone -
                            832-808-5574</a>
                    </h4>
                </h5>
            </div>
            <div class="row">
                <div class="col-sm-6 align-text-bottom"><img src="assets/img/drstella_logo.png" height="50px"></div>
                <div class="col-sm-6 text-right poweredby align-text-bottom" *ngIf="false">Powered by :<a
                        href="https://www.gracesoft.com" target="_blank"><img src="assets/img/gracesoft_logo.png"
                            height="70px"></a></div>
            </div>
        </mat-card-footer>
    </mat-card>

</div>