import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';

import { MaterialModule } from './material.module';
import { SurveyServicesService } from '../api-services/survey-services/survey-services.service';
import { ServiceUrl } from '../api-services/service-url';
import { DummyServiceService } from '../api-services/dummy-services/dummy-service.service';
import { ReportRequest } from '../core/models/LoginDetails';
import { FormatterService } from './formatter.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MaterialModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    MaterialModule,
    NgMultiSelectDropDownModule
  ],
  providers: [SurveyServicesService, ServiceUrl , DummyServiceService, ReportRequest, FormatterService]
})
export class SharedModule {}
