import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SurveySubmissionDetails } from 'src/app/Models/survey-details';

@Injectable({
  providedIn: 'root'
})
export class SharedComponentService {

  private _surveySubmissionDetails: BehaviorSubject<SurveySubmissionDetails>;
  public currentSurveySubmissionDetails: Observable<SurveySubmissionDetails>;

  constructor() { 
    
  }

  setSurveySWubmissionDetails(value: SurveySubmissionDetails) {
    this._surveySubmissionDetails = new BehaviorSubject<SurveySubmissionDetails>(value);
    this.currentSurveySubmissionDetails = this._surveySubmissionDetails.asObservable();
  }
}
