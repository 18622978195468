import { DatePipe } from "@angular/common";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AlertDialogComponent } from "src/app/admin/dialog/alert-dialog/alert-dialog.component";
import { SharedComponentService } from "src/app/api-services/shared-component-services/shared-component.service";
import {
  ExistingSurveyPatientInfo,
  patientSearchModel,
  SurveyDetails,
  SurveySubmissionDetails,
  IntakeFormLogModel,
  SurveyMedicine,
} from "src/app/Models/survey-details";

import { SurveyServicesService } from "../api-services/survey-services/survey-services.service";
import { AdminServicesService } from "../api-services/admin-services/admin-services.service";
import { ConfirmDialogComponent } from "src/app/admin/dialog/confirm-dialog/confirm-dialog.component";

import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { AcuteCareStateComponent } from "./acute-care-state/acute-care-state.component";
import { Observable, map, startWith } from "rxjs";
import { IDropdownSettings } from "ng-multiselect-dropdown";


@Component({
  selector: 'app-acute-care',
  templateUrl: './acute-care.component.html',
  styleUrls: ['./acute-care.component.sass']
})



export class AcuteCareComponent implements OnInit {

  surveyDetails: SurveyDetails[];
  surveyTypes: string[];
  procedureRates: number[] = [];
  initialDisplay: boolean = true;
  selectedSurveyDetailsId: number;
  faqHtml: SafeHtml = "";

  isSpinnerWait: boolean = false;
  patientState: string = "";
  

  refillMedicinePrices: any[];
  refillMedicines: any[];
  cuteCateSelectedSurveyDetails: SurveyDetails;

  dropdownList = [];
  selectedItems = [];
  regularMedicineList: SurveyMedicine[];
  drug_List: any[] = [];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true,
    enableCheckAll: false,
    limitSelection: 3
  };

  selectFormControl = new FormControl();
  searchTextboxControl = new FormControl();

  surveySubmissionDetails: SurveySubmissionDetails;
  constructor(
    private surveyServices: SurveyServicesService,
    private router: Router,
    public adminService: AdminServicesService,
    private sharedService: SharedComponentService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private _formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {

    this.freshLoad();
    this.loadPatientState();
  }
  bindResponseData(data: any, price: any): SurveyDetails[] {
    debugger;
    let serveyDetails: SurveyDetails[] = null;
    if (data && data.length > 0) {
      serveyDetails = [];
      data.forEach(f => {
        let details: SurveyDetails = {
          hasRecordCheck: false,
          procedureRate: price,
          shippingCharge: 0,
          surveyCode: f.id,
          surveyId: f.id,
          surveyName: f.service_name,
          surveyTypeCode: "5",
          surveyTypeName: f.service_type,
          description: f.description,
          created_at: f.created_at,
          service_mode: f.service_mode,
          updated_at: f.updated_at,
          RefPatientStatus: f.RefPatientStatus,
        };
        serveyDetails.push(details);
      });
    }
    return serveyDetails;
  }

  loadPatientState() {
    let data: any[] = null;
    const dialogRef = this.dialog.open(AcuteCareStateComponent, { data: data });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        if(data.isSubmit) {
          var acuteCateSelectedSurvey: SurveyDetails = JSON.parse(sessionStorage.getItem("AcuteCateSelectedSurvey"));
          this.cuteCateSelectedSurveyDetails = acuteCateSelectedSurvey;
          sessionStorage.setItem("_AcuteCarePatientState", data.stateName);
  
          this.isSpinnerWait = true;
          if (acuteCateSelectedSurvey.surveyId == 12) {
            // acute care refill process starts
            this.initialDisplay = false;
            this.getAcuteCareRefillMedicinePriceList()
            this.AcuteCareRefillDetails();
          }
          else if (acuteCateSelectedSurvey.surveyId == 11 || acuteCateSelectedSurvey.surveyId == 16) {
            // acute care general treatment 
            this.AcuteOfflineCareGeneralTreatmentDetails(data);
          }
          else {
            const dialogRef = this.dialog.open(ConfirmDialogComponent, {
              data: {
                message: 'General consult cannot be used for situations like: *Conditions requiring a thorough in-person examination *Prescription and refill of controlled substances *Any condition requiring in-person drug administration *Psychiatric emergencies *Highly specialized care and complex medical conditions',
                buttonText: {
                  ok: 'Proceed',
                  cancel: ''
                }
              }
            });
            dialogRef.afterClosed().subscribe((confirmed: boolean) => {
              if (confirmed) {
                const a = document.createElement('a');
                a.click();
                a.remove();
                this.onlineGeneralTreatment(data);
              }
            });
            
          }
  
        }
        else {
          this.router.navigate(["/intakeform"]);
        }
        
      }
      else {
        this.openAlertDialog("error handle.");
      }
    });
  }

  AcuteOfflineCareGeneralTreatmentDetails(data) {
    this.surveyServices.getServiceOfferDetails(data.stateName).subscribe((response) => {
      this.isSpinnerWait = false;
      if (response && response.data && response.data.success) {
        debugger;
        let offlineServiceOfferList = response.data.result.filter(f=>f.id != 25);
        this.surveyDetails = this.bindResponseData(offlineServiceOfferList, response.data.price);

        this.surveyTypes = [...new Set(this.surveyDetails.map((item) => item.surveyTypeName))];
        this.procedureRates = [...new Set(this.surveyDetails.map((item) => item.procedureRate))];

      } else {
        this.openAlertDialog("error handle.");
      }
    });
  }

  onlineGeneralTreatment(data) {
    this.surveyServices.getServiceOfferDetails(data.stateName).subscribe((response) => {
      this.isSpinnerWait = false;
      if (response && response.data && response.data.success) {
        let offlineServiceOfferList = response.data.result.filter(f=>f.id == 24);
        this.surveyDetails = this.bindResponseData(offlineServiceOfferList, response.data.price);

        this.surveyTypes = [...new Set(this.surveyDetails.map((item) => item.surveyTypeName))];
        this.procedureRates = [...new Set(this.surveyDetails.map((item) => item.procedureRate))];
        this.surveySelectorClick(24);
      } else {
        this.openAlertDialog("error handle.");
      }
    });
  }

  AcuteCareRefillDetails() {
    debugger;
    this.surveyServices.getAcuteCareDrugList().subscribe(res => {
      this.isSpinnerWait = false;
      if (res && res.data) {
        this.refillMedicines = res.data.result;
        if (this.refillMedicines && this.refillMedicines.length > 0) {
          let list: any[] = [];
          this.refillMedicines.forEach(f => {
            if (f.drug_list && f.drug_list.length > 0) {
              let option: any;
              f.drug_list.forEach(item => {
                option = { 
                  item_id: item.drug_id, 
                  item_text: item.drug_full_name + ", " + item.days,
                  item_qty_text: item.drug_full_name,
                  item_category_id: f.category_id,
                  item_category_name: f.category_name,
                  item_tier: item.tier,
                  item_price: this.calculateAcuteCareRefillMedicinePrice(item.tier, item.price),
                  iten_basePrice: item.price,
                  item_quantity: item.quantity,
                  item_days: item.days
                };
                list.push(option);
              });
            }
          });
          this.dropdownList = list;
          this.setRegularMedicines(this.selectedItems);
        }

        console.log(this.refillMedicines);
      }
    });
  }

  getAcuteCareRefillMedicinePriceList() {
    this.surveyServices.getAcuteCareRefillMedicineDetails().subscribe(res => {
      if(res && res.data) {
        this.refillMedicinePrices = res.data;
      }
    });
  }

  calculateAcuteCareRefillMedicinePrice(tierName: string, medPrice: number): number {
    debugger;
    let selectedPrice: number = medPrice;
    if(tierName) {
      selectedPrice = this.refillMedicinePrices.filter(f => f.tier.toUpperCase() ==  tierName.trim().toUpperCase())[0].price;
    }
    return selectedPrice;
  }

  onItemSelect(item: any) {
    this.setRegularMedicines(this.selectedItems);
  }
  onItemDeSelect(item: any) {
    this.setRegularMedicines(this.selectedItems);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  categoryChange(event) {

  }

  setRegularMedicines(selectedItems: any[]) {
    if(selectedItems && selectedItems.length > 0) {
      this.regularMedicineList = [];
      selectedItems.forEach(f => {
        let result = this.dropdownList.find(w=>w.item_id == f.item_id);
        let medicine: SurveyMedicine = {
          activeMedicine: true,
          amount: Number(result.item_price),
          acuteCare_refillPrice: result.iten_basePrice,
          isChecked: true,
          isMandatory: true,
          medicineName: result.item_qty_text,
          quantity: 0,
          medicineId: result.item_id,
          categoryName: result.item_category_name,
          categoryId: result.item_category_id,
          medicinePackageId: Number(result.item_category_id),
          tier: result.item_tier,
          days: result.item_days
        }
        this.regularMedicineList.push(medicine);
        this.drug_List.push(result);
      });
    }
  }

  acuteCareRefillSelection() {
    debugger;
    let selectedSurvey: SurveyDetails = {
      hasRecordCheck: this.cuteCateSelectedSurveyDetails.hasRecordCheck,
      procedureRate: this.cuteCateSelectedSurveyDetails.procedureRate,
      shippingCharge: this.cuteCateSelectedSurveyDetails.shippingCharge,
      surveyCode: this.cuteCateSelectedSurveyDetails.surveyId,
      surveyId: this.cuteCateSelectedSurveyDetails.surveyId,
      surveyName: "Regular medicine refill",
      surveyTypeCode: "5",
      surveyTypeName: "Acute Care",
      RefPatientStatus: this.cuteCateSelectedSurveyDetails.RefPatientStatus,
    }
    sessionStorage.setItem("selectedSurvey", JSON.stringify(selectedSurvey));
    sessionStorage.setItem("_isAcuteSurvey", 'yes');
    sessionStorage.setItem("_acuteCareRegularRefillMedicines", JSON.stringify(this.regularMedicineList))
    this.router.navigate(["/acute-care/steps"]);
  }

  freshLoad() {
    sessionStorage.removeItem("selectedSurvey");
    sessionStorage.removeItem("procedureRate");
    sessionStorage.removeItem("existingSubmissionId");
    sessionStorage.removeItem("existingPatientId");
    localStorage.removeItem("intakeFormEmail");
    sessionStorage.removeItem("patientAns");
    sessionStorage.removeItem("_isAcuteSurvey");

    sessionStorage.removeItem("_acuteCare");
    sessionStorage.removeItem("_acuteAnswer");
    sessionStorage.removeItem("_acuteAnswerDesc");
    sessionStorage.removeItem("_acuteCareStepsDetails");
    sessionStorage.removeItem("_criticalDataForPaymentPage");
    sessionStorage.removeItem("_AcuteCarePatientState");

  }

  getSurveyDetailsByType(selectedType: string): SurveyDetails[] {
    return this.surveyDetails.filter((f) => f.surveyTypeName == selectedType);
  }

  surveySelectorClick(surveyId: any): void {
    debugger;
    console.log(surveyId);
    this.selectedSurveyDetailsId = surveyId;
    debugger;
    this.openRatePopup(surveyId);
    let selectedSurvey: SurveyDetails = this.surveyDetails.find((f) => f.surveyId == surveyId);
    sessionStorage.setItem("selectedSurvey", JSON.stringify(selectedSurvey));
    sessionStorage.setItem("procedureRate", JSON.stringify(this.procedureRates));
    if (!selectedSurvey.hasRecordCheck) {
      sessionStorage.setItem("_isAcuteSurvey", 'yes');
      this.router.navigate(["/acute-care/steps"]);
    } else {
      this.initialDisplay = false;
    }
  }

  openRatePopup(surveyId: number) {

  }

  submitExistingPatientForm() {

  }

  openAlertDialog(alertMessage: string) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: {
        message: alertMessage,
        buttonText: {
          cancel: "Proceed",
        },
      },
    });
  }


  public checkError = (controlName: string, errorName: string) => {

  };

}


