import { Injectable } from '@angular/core';
import { ServiceUrl } from '../service-url';
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserMenues } from 'src/app/Models/user-menues';
import { UserMap } from 'src/app/Models/user-map';

@Injectable({
  providedIn: 'root'
})

export class DummyServiceService {

  private _url : string = "https://localhost:5001/api/admin/GetLoginUserTypes";

  private _user : string = "https://localhost:5001/api/admin/GetSelectedMenuesByUserType";

  private _usermap : string = "https://localhost:5001/api/admin/MapMenuAccessToUser";


  constructor(private httpClient: HttpClient) { }

  getUserRole(){
    return this.httpClient.get(this._url);
  }

  getUsersPermission(id: string): Observable<UserMenues[]> {  
    return this.httpClient.get<UserMenues[]>(this._user + '?id=' + id);  
  }  

  updateUsersPermission(usermenu: UserMenues): Observable<UserMenues> {  
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
    return this.httpClient.put<UserMenues>(this._usermap ,  
    usermenu, httpOptions);  
  }  

  updateUserMap(usermap: UserMap): Observable<UserMap[]> {  
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json'}) };  
    return this.httpClient.post<UserMap[]>(this._usermap ,  
      usermap , httpOptions);  
  }  

}
